import { useShowNotification } from '@elotech/components';
import { useLoading } from 'common/hooks';
import { PERMITE_SOLICITACAO_ASSINATURA_USUARIO_DUPLICADA_ARQUIVO } from 'pages/parametros/Parametros';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ParametroService } from 'service';
import { getUsernameSelector } from 'state';
import { AssinaturaSituacaoEnum, ProcessoArquivoDTO } from 'types';

export const usePermiteAssinar = () => {
  const [loading, setLoading, awaitPromise] = useLoading();
  const [permiteAssinaturasDuplicadas, setPermiteAssinaturasDuplicadas] =
    useState(false);
  const showNotification = useShowNotification();
  const userLogadoId = useSelector(getUsernameSelector) as string;

  useEffect(() => {
    setLoading(
      ParametroService.findParametro(
        PERMITE_SOLICITACAO_ASSINATURA_USUARIO_DUPLICADA_ARQUIVO
      ).then(({ data }) => setPermiteAssinaturasDuplicadas(data.valor === 'S'))
    );
  }, []);

  const handlePermiteAssinar = async (
    arquivo: ProcessoArquivoDTO,
    assinar: () => void
  ) => {
    await awaitPromise();

    if (loading) {
      showNotification({
        level: 'error',
        message: 'Por favor, tente novamente.'
      });
      return;
    }

    if (!arquivo) {
      showNotification({
        level: 'error',
        message: 'Por favor, selecione um arquivo.'
      });
      return;
    }

    const usuarioJaAssinou = arquivo.assinaturas?.some(
      assinatura =>
        assinatura.usuario?.id === userLogadoId &&
        assinatura.situacao === AssinaturaSituacaoEnum.ASSINADO
    );

    const permiteAssinarNovamente =
      arquivo.possuiAssinaturaPendenteUsuarioLogado ||
      permiteAssinaturasDuplicadas;

    if (usuarioJaAssinou && !permiteAssinarNovamente) {
      showNotification({
        level: 'error',
        message:
          'O Usuário já assinou, portanto, conforme parametrização, o usuário só pode assinar novamente caso tenha a assinatura solicitada anteriormente.'
      });
      return;
    }

    assinar();
  };

  return handlePermiteAssinar;
};
