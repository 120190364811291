export enum TipoAndamentoEnum {
  EMAIL = 'EMAIL',
  PREVISAOCONCLUSAO = 'PREVISAOCONCLUSAO',
  ANEXOEXTERNO = 'ANEXOEXTERNO',
  OFICIO = 'OFICIO',
  SUSPENSAO = 'SUSPENSAO',
  ALTERACAOCATEGORIA = 'ALTERACAOCATEGORIA',
  COMUNICACAOINTERNA = 'COMUNICACAOINTERNA',
  DISPENSAPRORROGACAO = 'DISPENSAPRORROGACAO',
  DEFINICAOSIGILOSO = 'DEFINICAOSIGILOSO',
  BLOQUEIOANEXOEXTERNO = 'BLOQUEIOANEXOEXTERNO',
  ALTERASITUACAO = 'ALTERASITUACAO',
  TAD = 'TAD',
  DATAACORDO = 'DATAACORDO',
  PRAZO = 'PRAZO',
  ALTERAREQUERENTE = 'ALTERAREQUERENTE'
}

export const tipoAndamentoEnum: Record<TipoAndamentoEnum, string> = {
  EMAIL: 'Email',
  PREVISAOCONCLUSAO: 'Previsão Conclusão',
  ANEXOEXTERNO: 'Anexo Externo',
  OFICIO: 'Ofício',
  SUSPENSAO: 'Suspensão',
  ALTERACAOCATEGORIA: 'Alteração de Categoria',
  COMUNICACAOINTERNA: 'Comunicação Interna',
  DISPENSAPRORROGACAO: 'Dispensa Prorrogação',
  DEFINICAOSIGILOSO: 'Definição Sigiloso',
  BLOQUEIOANEXOEXTERNO: 'Libera/Bloqueia Anexos Consulta Externa',
  ALTERASITUACAO: 'Altera Situação',
  ALTERAREQUERENTE: 'Altera Requerente',
  TAD: 'TAD',
  DATAACORDO: 'Data de Acordo',
  PRAZO: 'Prazo'
};

export const tipoAndamentoMpEnum: Record<TipoAndamentoEnum, string> = {
  EMAIL: 'Email',
  PREVISAOCONCLUSAO: 'Previsão Conclusão',
  ANEXOEXTERNO: 'Anexo Externo',
  OFICIO: 'Ofício',
  SUSPENSAO: 'Suspensão',
  ALTERACAOCATEGORIA: 'Alteração de Categoria',
  COMUNICACAOINTERNA: 'Comunicação Interna',
  DISPENSAPRORROGACAO: 'Dispensa Prorrogação',
  DEFINICAOSIGILOSO: 'Definição Sigiloso',
  BLOQUEIOANEXOEXTERNO: 'Libera/Bloqueia Anexos Consulta Externa',
  ALTERASITUACAO: 'Altera Situação',
  ALTERAREQUERENTE: 'Altera Requerente',
  TAD: 'TAD',
  DATAACORDO: 'Data de Acordo',
  PRAZO: 'Prazo'
};
