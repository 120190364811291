import {
  ActionButton,
  ActionsGroup,
  BasicInput,
  Col,
  DateUtils,
  ExtraInfo,
  FormattedDate,
  FormattedDateTime,
  Panel,
  Row,
  Table,
  TableChild
} from '@elotech/components';
import { Ellipsis, alert } from 'common/components';
import { useExpandedIds } from 'common/hooks';
import { formatarData } from 'common/utils';
import { Formik } from 'formik';
import {
  BLOQUEIA_ALTERACAO_ANDAMENTO_COM_ANDAMENTO_POSTERIOR,
  BLOQUEIA_ALTERACAO_ANDAMENTO_USUARIO_LOGADO_DIFERENTE
} from 'pages/parametros/Parametros';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArquivoService, ParametroService } from 'service';
import { getConfigSelector, getUserInfoSelector } from 'state';
import { isAuthenticated } from 'utils';

import { TipoAndamentoEnum } from '../../../types';

export const ProcessoAndamentosList = ({
  andamentos = [],
  processosArquivos = [],
  onRemove,
  onEdit,
  loading,
  canEdit,
  canRemove
}) => {
  const { expandedIds, handleExpandedIds } = useExpandedIds();
  const usuarioLogado = useSelector(getUserInfoSelector);
  const [blockAltUsuario, setBlockAltUsuario] = useState(false);
  const [blockAltAndamentoPosterior, setBlockAltAndamentoPosterior] =
    useState(false);
  const isLogged = isAuthenticated();
  const { isProcessoMp } = useSelector(getConfigSelector);
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    if (isLogged) {
      ParametroService.findParametro(
        BLOQUEIA_ALTERACAO_ANDAMENTO_USUARIO_LOGADO_DIFERENTE
      ).then(({ data }) => setBlockAltUsuario(data.valor === 'S'));

      ParametroService.findParametro(
        BLOQUEIA_ALTERACAO_ANDAMENTO_COM_ANDAMENTO_POSTERIOR
      ).then(({ data }) => setBlockAltAndamentoPosterior(data.valor === 'S'));
    }
  }, []);

  const getMaiorId = () => {
    if (andamentos.length === 0) {
      return null;
    }

    const andamentoMaisRecente = andamentos.reduce(
      (andamentoAtual, proximoAndamento) => {
        return proximoAndamento.id > andamentoAtual.id
          ? proximoAndamento
          : andamentoAtual;
      },
      andamentos[0]
    );

    return andamentoMaisRecente.id;
  };

  const bloqueiaAlteracaoAndamento = alteracao => {
    const usuarioDiferente =
      blockAltUsuario && usuarioLogado.nome !== alteracao.usuarioNome;
    const andamentoPosterior =
      blockAltAndamentoPosterior && alteracao.id !== getMaiorId();

    return usuarioDiferente || andamentoPosterior;
  };

  const situacaoProcessoAlterada = tipoAndamento => {
    return tipoAndamento?.tipo === TipoAndamentoEnum.ALTERASITUACAO;
  };

  const remove = (andamento, index) => {
    alert({
      type: 'question',
      title: 'Remover',
      text: 'Deseja mesmo excluir este andamento?',
      onConfirm: () => onRemove(andamento, index)
    });
  };

  const renderArquivos = andamento => {
    const andamentosArquivos = processosArquivos.filter(
      arquivo => arquivo.idAndamento === andamento.id
    );

    if (andamentosArquivos.length === 0) return null;

    return (
      <>
        <div className="mt-xs">
          <div className="pl-none panel-title">
            Documentos Inseridos na Resposta:
          </div>
          <table className="panel-table striped fancy">
            <thead>
              <tr>
                <th>Nome Documento</th>
                <th>Data Envio</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {andamentosArquivos.map((item, index) => (
                <>
                  <tr className="reduced" key={item.id}>
                    <td>{item.nome}</td>
                    <td>
                      <FormattedDateTime value={item.dataCriacao} />
                    </td>
                    <td>
                      <ActionsGroup>
                        <ActionButton
                          icon="eye"
                          label="Visualizar"
                          onClick={() => ArquivoService.preview(item)}
                        />
                      </ActionsGroup>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderInnerComponent = andamento => {
    if (expandedIds.includes(andamento.id)) {
      return (
        <>
          <TableChild>
            <>
              <Col size={12}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    resposta: andamento.respostaRequerente,
                    dataHora: andamento.dataHoraResposta
                  }}
                >
                  <>
                    <Row>
                      <BasicInput
                        size={12}
                        name="resposta"
                        label="Resposta do Requerente"
                        render={({ field }) => (
                          <textarea value={field.value} readOnly />
                        )}
                      />
                    </Row>
                    <Row>
                      <BasicInput
                        size={12}
                        label="Data/Hora"
                        hint="Data/hora do preenchimento da resposta do requerente"
                        name="dataHora"
                        render={({ field }) => (
                          <input
                            readOnly
                            {...field}
                            type="datetime-local"
                            max="9999-12-31T00:00:00"
                          />
                        )}
                      />
                    </Row>
                  </>
                </Formik>
              </Col>
              {renderArquivos(andamento)}
            </>
          </TableChild>
        </>
      );
    }
    return null;
  };

  const handleFinalizacao = andamento => {
    const { dataTermino, dataFinalizacao } = andamento;
    const finalizado = andamento.finalizado && andamento.dataFinalizacao;

    if (!andamento.tipoAndamento?.controlaPrazo || !dataTermino) {
      if (finalizado) {
        return (
          <ExtraInfo
            letter="F"
            value={`Finalizado em ${formatarData(dataFinalizacao)}`}
          />
        );
      }
      return null;
    }

    const now = DateUtils.getTodayDate();

    if (!finalizado && dataTermino < now) {
      return <ExtraInfo letter="V" value="Prazo vencido" color="negative" />;
    }

    if (finalizado && dataTermino < dataFinalizacao) {
      return (
        <ExtraInfo
          letter="P"
          value={`Finalizado fora do prazo em ${formatarData(dataFinalizacao)}`}
          color="warning"
        />
      );
    }

    if (finalizado) {
      return (
        <ExtraInfo
          letter="F"
          value={`Finalizado dentro do prazo em ${formatarData(
            dataFinalizacao
          )}`}
        />
      );
    }

    return <ExtraInfo letter="A" value="Aberto e dentro do prazo" />;
  };

  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
  };

  const ordenaAndamentos = useMemo(() => {
    const order = andamentos
      .slice()
      .sort((a, b) => a.id - b.id)
      .map((andamento, index) => ({ ...andamento, index: index + 1 }));
    return sortDirection === 'asc' ? order : order.reverse();
  }, [andamentos, sortDirection]);

  return (
    <Panel
      isTable
      collapsed={false}
      expansible
      isForm={false}
      title="Andamentos"
    >
      <Table
        fixed
        loading={loading}
        values={!isProcessoMp ? andamentos : ordenaAndamentos}
        renderInnerComponent={renderInnerComponent}
        keyExtractor={a => `${a.id}`}
        sortOnHeaderColumnClick={handleSort}
        defaultSort={{ name: 'id', direction: 'desc' }}
      >
        {isProcessoMp && (
          <Table.Column
            sortable
            name="id"
            header="Ordem"
            value={a => a.index}
          />
        )}

        <Table.Column
          header="Tipo"
          value={a => (
            <>
              {handleFinalizacao(a)}
              {`${a.tipoAndamento?.descricao || ''}`}
            </>
          )}
        />
        <Table.Column
          header="Data/Hora"
          value={a => <FormattedDateTime value={a.dataHora} />}
        />
        <Table.Column
          header="Usuário Registro"
          value={a =>
            a.usuario === 'USER_EXTERNO' ? 'Usuário Externo' : a.usuario
          }
        />
        <Table.Column
          header="Descrição"
          value={a => {
            const dm = new DOMParser().parseFromString(
              a.descricao || '',
              'text/html'
            );
            return <Ellipsis value={dm.body.textContent} maxLength={10} />;
          }}
        />
        <Table.Column
          header="Data Início"
          value={a => <FormattedDate value={a.dataInicio} />}
        />
        <Table.Column
          header="Data Fim"
          value={a => <FormattedDate value={a.dataTermino} />}
        />
        <Table.Column header="Trâmite Origem" value={a => a.tramite} />
        <Table.Column
          header="Solicitação ao Requerente"
          value={a => (a.solicitacaoRequerente ? 'Sim' : 'Não')}
        />
        <Table.Column
          header="Bloqueio Anexo Externo"
          value={a => (a.bloqueioAnexoExterno ? 'Sim' : 'Não')}
        />
        <Table.Column
          header=""
          value={(a, index) => (
            <ActionsGroup>
              {a.respostaRequerente && (
                <ActionButton
                  icon="comments"
                  label="Resposta do Requerente"
                  onClick={() => {
                    return handleExpandedIds(a.id);
                  }}
                />
              )}
              {!a.respostaRequerente &&
                canEdit &&
                !bloqueiaAlteracaoAndamento(a) &&
                !situacaoProcessoAlterada(a.tipoAndamento) && (
                  <ActionButton
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => onEdit(a)}
                  />
                )}
              {!a.respostaRequerente &&
                canRemove &&
                !bloqueiaAlteracaoAndamento(a) &&
                !situacaoProcessoAlterada(a.tipoAndamento) && (
                  <ActionButton
                    icon="trash"
                    label="Remover"
                    onClick={() => remove(a, index)}
                  />
                )}
            </ActionsGroup>
          )}
        />
      </Table>
    </Panel>
  );
};
