import {
  Info,
  Loading,
  Modal,
  Panel,
  useShowNotification
} from '@elotech/components';
import { alert } from 'common/components';
import { useLoading } from 'common/hooks';
import { isNumber } from 'common/utils';
import { useEffect, useState } from 'react';
import {
  ParametroService,
  ProcessoAnexoService,
  ProcessoArquivoService,
  ProcessoRelatorioService,
  ProcessoService,
  SituacaoService
} from 'service';

import {
  GERA_PAPELETA_AUTOMATICO,
  IMPRIME_PAPELETA_ARQUIVAMENTO,
  IMPRIME_PAPELETA_ENCAMINHAMENTO,
  NAO_EXIBE_PROC_FIS_TRAMITACAO,
  NAO_EXIBE_UPLOAD_TRAMITACAO,
  PARAM_PROCESSO_MP
} from '../../parametros/Parametros';
import {
  componentByTipo,
  paramSituacaoByTipo,
  titleByTipo,
  tramitarByTipo,
  validarByTipo
} from './TramiteUtils';

export const TramiteFormModal = ({
  tipo,
  onClose,
  processo,
  onUpdateSearch
}) => {
  const [loading, setLoading] = useLoading();
  const [hasProcessoPai, setHasProcessoPai] = useState(false);
  const [situacaoPadrao, setSituacaoPadrao] = useState(undefined);
  const [paramProcessoMp, setParamProcessoMp] = useState(false);
  const [ocultaProcFis, setOcultaProcFis] = useState(false);
  const [ocultaArquivo, setOcultaArquivo] = useState(false);
  const [mostraAlert, setMostraAlert] = useState(false);
  const [imprimePapeletaEncaminhamento, setImprimePapeletaEncaminhamento] =
    useState(false);
  const [imprimePapeletaArquivamento, setImprimePapeletaArquivamento] =
    useState(false);
  const showNotification = useShowNotification();

  useEffect(() => {
    setLoading(
      Promise.all([
        ParametroService.findParametro(PARAM_PROCESSO_MP),
        ParametroService.findParametro(NAO_EXIBE_PROC_FIS_TRAMITACAO),
        ParametroService.findParametro(NAO_EXIBE_UPLOAD_TRAMITACAO),
        ParametroService.findParametro(paramSituacaoByTipo[tipo]),
        ParametroService.findParametro(GERA_PAPELETA_AUTOMATICO),
        ParametroService.findParametro(IMPRIME_PAPELETA_ENCAMINHAMENTO),
        ParametroService.findParametro(IMPRIME_PAPELETA_ARQUIVAMENTO),
        ProcessoAnexoService.hasProcessoPai(processo.id)
      ]).then(
        ([
          processoMp,
          ocultaProcFis,
          ocultaArquivo,
          situacao,
          geraPapeleta,
          imprimePapeletaEncaminhamento,
          imprimePapeletaArquivamento,
          hasProcessoPai
        ]) => {
          setParamProcessoMp(processoMp.data?.valor === 'S');
          setOcultaArquivo(ocultaArquivo.data?.valor === 'S');
          setOcultaProcFis(ocultaProcFis.data?.valor === 'S');
          setImprimePapeletaEncaminhamento(
            imprimePapeletaEncaminhamento.data.valor === 'S'
          );
          setImprimePapeletaArquivamento(
            imprimePapeletaArquivamento.data.valor === 'S'
          );
          setHasProcessoPai(hasProcessoPai.data);

          if (situacao.data && isNumber(situacao.data.valor)) {
            return SituacaoService.findById(situacao.data.valor).then(
              ({ data }) => setSituacaoPadrao(data)
            );
          }
        }
      )
    );
  }, [tipo, processo, setLoading]);

  if (!tipo) {
    return null;
  }

  const TramiteForm = componentByTipo[tipo];
  const title = `${titleByTipo[tipo]} processo: ${processo.numero}/${processo.ano}`;

  const gerarPapeleta = (processos = [], tipo = 'LOCAL') => {
    const params = processos.map(p => ({
      numero: p.numero,
      ano: p.ano,
      tipo: p.tipo.id,
      sequencia: p.ultimoTramite?.sequencia || 0
    }));

    return setLoading(
      ProcessoRelatorioService.createPapeletaTramite(params, tipo)
    );
  };

  const handleImpressao = (processos = []) =>
    alert({
      type: 'success',
      showCancelButton: true,
      cancelButtonText: 'Local',
      confirmButtonText: 'Lote',
      title: 'Imprimir Papeleta',
      text: 'Qual agrupamento deseja utilizar?',
      onConfirm: () => gerarPapeleta(processos, 'LOTE'),
      onCancel: () => gerarPapeleta(processos, 'LOCAL')
    });

  const imprimePapeleta = (
    imprimePapeletaEncaminhamento,
    imprimePapeletaArquivamento,
    tipo
  ) => {
    return (
      (imprimePapeletaEncaminhamento &&
        (tipo === 'ENCAMINHAR' || tipo === 'RECEBER_ENCAMINHAR')) ||
      (imprimePapeletaArquivamento && tipo === 'ARQUIVAR')
    );
  };

  const tramitar = values => {
    if (hasProcessoPai) {
      return;
    }

    let tramitados = [];
    const wrapper = {
      ...values,
      processos: [processo],
      processoFisico: !!values.processo?.processoFisico
    };

    const onValidar = validarByTipo[tipo];
    const onTramitar = tramitarByTipo(wrapper)[tipo];

    return setLoading(
      onValidar(processo.id)
        .then(() => {
          if (values?.assunto?.id) {
            return ProcessoService.atualizarAssunto(processo, values.assunto);
          }

          return Promise.resolve();
        })
        .then(() => {
          return onTramitar();
        })
        .then(({ data }) => {
          tramitados = data;
          return ProcessoArquivoService.salvarArquivos(
            processo.id,
            values.arquivos
          );
        })
        .then(() => onUpdateSearch?.())
    )
      .then(() => {
        const alertConfig = {
          title,
          type: 'success',
          text: 'Tramitação realizada com sucesso!'
        };

        setMostraAlert(true);

        if (
          imprimePapeleta(
            imprimePapeletaEncaminhamento,
            imprimePapeletaArquivamento,
            tipo
          )
        ) {
          alertConfig.title = `Tramitação realizada com sucesso! ${title}`;
          alertConfig.text = 'Deseja imprimir a papeleta do processo?';
          alertConfig.showCancelButton = true;
          alertConfig.confirmButtonText = 'Sim';
          alertConfig.cancelButtonText = 'Não';
          alertConfig.onConfirm = () => handleImpressao(tramitados);
        }

        return alert(alertConfig);
      })
      .catch(error =>
        showNotification({
          level: 'error',
          message: `${
            error.response?.data?.message ||
            error.response?.data ||
            error.message
          }`
        })
      )
      .finally(() => onClose(setMostraAlert(false)));
  };

  if (mostraAlert) {
    return <Loading loading={loading} />;
  }
  return (
    <Modal onClose={onClose} maxWidth="60%">
      <Loading loading={loading} />
      <Panel isForm title={title}>
        {hasProcessoPai && (
          <Info classes="mt-xs warning center">
            Processo está anexado a outro, não será possível realizar a
            tramitação!
          </Info>
        )}
        <TramiteForm
          processo={processo}
          onTramitar={tramitar}
          situacao={situacaoPadrao}
          hasProcessoPai={hasProcessoPai}
          paramProcessoMp={paramProcessoMp}
          ocultaArquivo={ocultaArquivo}
          ocultaProcFis={ocultaProcFis}
        />
      </Panel>
    </Modal>
  );
};
