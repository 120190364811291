import {
  BasicInput,
  FormikAutocomplete,
  FormikInputDate,
  FormikSelect,
  Row
} from '@elotech/components';
import { Option } from 'common/utils';
import React from 'react';

import { ProcessoService, UsuarioService } from '../../service';
import {
  TipoRequerenteMpOptions,
  TipoRequeridoMpOptions,
  UsuarioLocalDTO
} from '../../types';

type Props = {
  local: any;
};

const ProcedimentoInternoSection: React.FC<Props> = ({ local }) => {
  return (
    <>
      <Row>
        <FormikInputDate
          name="processoMp.dataAutuacao"
          label="Data Autuação"
          size={6}
        />
        <BasicInput name="processoMp.requerente" label="Requerente" size={6} />
      </Row>
      <Row>
        <FormikSelect<Option>
          name="processoMp.tipoRequerido"
          label="Tipo Requerido"
          options={TipoRequeridoMpOptions}
          size={6}
          getOptionLabel={obj => obj.description}
          getOptionValue={obj => obj.value}
        />
        <BasicInput name="processoMp.requerido" label="Requerido" size={6} />
      </Row>
      <Row>
        <FormikAutocomplete<UsuarioLocalDTO>
          size={6}
          fast={false}
          name="processoMp.distribuicao"
          label="Distribuição"
          disabled={!local}
          getOptionLabel={u => u.nomeUsuario}
          onSearch={search =>
            UsuarioService.findAllUsuarioEntidadeAtualAutoComplete(search)
          }
        />
      </Row>
      <Row>
        <FormikAutocomplete
          name="procedimentoOrigem"
          label="Procedimento Origem"
          size={6}
          onSearch={ProcessoService.findAllAutocomplete}
          getOptionLabel={(p: any) =>
            `Tipo:${p.tipo?.descricao} - Número:${p.numero} - Ano:${p.ano}`
          }
        />
        <FormikSelect<Option>
          name="processoMp.tipoRequerente"
          label="Tipo Requerente"
          options={TipoRequerenteMpOptions}
          size={6}
          getOptionLabel={obj => obj.description}
          getOptionValue={obj => obj.value}
        />
      </Row>
    </>
  );
};

export default ProcedimentoInternoSection;
