import { NotificationReducer } from '@elotech/components';
import { combineReducers } from 'redux';
import {
  AuthReducer,
  ConfigReducer,
  EntidadeReducer,
  KeycloakReducer,
  ProcessosAgrupadosReducer
} from 'state/reducers';

export const rootReducer = (config = {}, configExterna = {}) => {
  const ConfigExternaReducer = () => configExterna;

  return combineReducers({
    auth: AuthReducer,
    config: ConfigReducer(config),
    configExterna: ConfigExternaReducer,
    entidade: EntidadeReducer,
    notification: NotificationReducer,
    processosAgrupados: ProcessosAgrupadosReducer,
    user: KeycloakReducer
  });
};
