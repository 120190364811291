import { ActionType } from '@elotech/components';
import { StorageService } from 'libs';
import { ConfigTypes } from 'state/types';

export const ConfigReducer =
  (initialState = {}) =>
  (state = initialState, action: ActionType<any>) => {
    switch (action.type) {
      case ConfigTypes.REFRESH:
        StorageService.saveItemAsJson('config', action.payload);
        return action.payload;
      default:
        return state;
    }
  };
