import { ProtocoloConfigService } from 'service';
import { ConfigTypes } from 'state/types/ConfigTypes';

let running = false;

export const refreshConfig =
  (entidadeId: number, onFinish: () => void) => dispatch => {
    if (running) {
      onFinish();
      return;
    }

    running = true;

    return ProtocoloConfigService.getConfig(entidadeId)
      .then(({ data }) => {
        dispatch({
          type: ConfigTypes.REFRESH,
          payload: data
        });
      })
      .finally(() => {
        running = false;
        onFinish();
      });
  };
