import { Col, Row } from '@elotech/components';
import { ChangeEvent } from 'react';
import { FormGroup } from 'react-bootstrap';

import { InlineButton } from '../inline-button';
import { Label } from '../label';

type Props = {
  name: string;
  fileName?: string;
  label: string;
  size: number;
  onChange: (event: ChangeEvent<any>) => void;
  onRemove: () => void;
  onDownload: () => void;
};

const InputFile = ({
  name,
  fileName,
  label,
  size,
  onChange,
  onRemove,
  onDownload
}: Props) => {
  return (
    <>
      <Col md={size}>
        <FormGroup>
          <Label>{label}</Label>

          <Row>
            <Col md={9}>
              <div className="file-uploader">
                <input
                  type="file"
                  name={name}
                  className="file-uploader-input"
                  onChange={event => onChange(event)}
                  title="Clique aqui ou arraste para anexar o arquivo"
                />

                <label
                  className="input"
                  data-title={`${
                    fileName
                      ? fileName
                      : 'Clique aqui ou arraste para anexar o arquivo'
                  }`}
                />
                <div className="file-uploader-icon" />
              </div>
            </Col>
            <Col md={3}>
              <InlineButton
                label="Baixar"
                icon="fa fa-download"
                onClick={onDownload}
              />
              <InlineButton
                label="Limpar"
                icon="fa fa-times"
                onClick={onRemove}
              />
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </>
  );
};

export default InputFile;
