import {
  BasicInput,
  Col,
  Loading,
  Modal,
  Panel,
  Row,
  Yup
} from '@elotech/components';
import { useLoading } from 'common/hooks';
import {
  formataCpfOuCnpj,
  getUrl,
  retirarFormatacaoCnpj,
  retirarFormatacaoCpf
} from 'common/utils';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { useState } from 'react';
import { FormGroup } from 'react-bootstrap';

import { UNCPessoa } from '../../../type';
import { TipoPessoa } from '../../../type/enum';
import { InlineButton } from '../../inline-button';

type Props = {
  onClose: (pessoa?: UNCPessoa) => void;
  onAdd: (values: UNCPessoa) => Promise<UNCPessoa>;
  findPessoa: (documento: string) => Promise<any>;
};

const initialValues: UNCPessoa = {
  cnpjCpf: '',
  nome: '',
  tipoPessoa: TipoPessoa.FISICA
};

const validationSchema = Yup.object().shape({
  cnpjCpf: Yup.string()
    .required('CNPJ/CPF é obrigatório')
    .test('cnpjCpf', 'CNPJ/CPF inválido', cnpjCpf => {
      return cnpjCpf && (cnpjCpf.length === 11 || cnpjCpf.length === 14);
    }),
  nome: Yup.string().required('Nome é obrigatório')
});

export const AddPessoaModal = ({ onClose, onAdd, findPessoa }: Props) => {
  const [loading, setLoading] = useLoading();
  const [abreCadastro, setAbreCadastro] = useState(false);

  const onSubmit = (
    values: UNCPessoa,
    actions: FormikActions<UNCPessoa>
  ): void => {
    const resultado = {} as any;
    setLoading(
      onAdd(values)
        .then(pessoa => {
          resultado.pessoa = pessoa;
          if (abreCadastro) {
            window.open(getUrl('unico', `/pessoas/${pessoa.id}`), '_blank');
          }
        })
        .finally(() => {
          onClose(resultado.pessoa);
          actions.resetForm(initialValues);
        })
    );
  };

  const onChangeCnpjCpf = (e, formProps: FormikProps<UNCPessoa>) => {
    const cnpjCpf = e.target.value || '';
    const cnpjCpfSemFormatacao =
      cnpjCpf.length > 14
        ? retirarFormatacaoCnpj(cnpjCpf)
        : retirarFormatacaoCpf(cnpjCpf);
    const tipoPessoa = cnpjCpfSemFormatacao.length <= 11 ? 'F' : 'J';

    formProps.setFieldValue('cnpjCpf', cnpjCpfSemFormatacao);
    formProps.setFieldValue('tipoPessoa', tipoPessoa);
  };

  const onBlurCnpjCpf = (formProps: FormikProps<UNCPessoa>) => {
    const { cnpjCpf } = formProps.values;
    const cnpjCpfSemFormatacao =
      cnpjCpf!.length > 14
        ? retirarFormatacaoCnpj(cnpjCpf!)
        : retirarFormatacaoCpf(cnpjCpf!);

    setLoading(
      findPessoa(cnpjCpfSemFormatacao).then(({ data }) => {
        formProps.setFieldValue('id', data?.id);
        formProps.setFieldValue('nome', data?.nome || '');
      })
    );
  };

  const salvarCadastro =
    (deveAbrirCadastro: boolean, formProps: FormikProps<UNCPessoa>) => () => {
      setAbreCadastro(deveAbrirCadastro);
      return formProps.submitForm();
    };

  return (
    <Modal onClose={onClose} maxWidth="75%">
      <Loading loading={loading} />
      <Panel title="Inserir Pessoa" isForm>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formProps => {
            return (
              <Form>
                <Row>
                  {!!formProps?.values?.id && (
                    <BasicInput name="id" label="Id" size={1} disabled />
                  )}
                  <BasicInput
                    size={2}
                    fast={false}
                    disabled={loading}
                    hint="Informar apenas os números"
                    name="cnpjCpf"
                    label="CNPJ/CPF"
                    render={({ field }) => (
                      <input
                        {...field}
                        maxLength={18}
                        value={formataCpfOuCnpj(field.value)}
                        onChange={e => onChangeCnpjCpf(e, formProps)}
                        onBlur={() => onBlurCnpjCpf(formProps)}
                      />
                    )}
                  />
                  <BasicInput
                    name="nome"
                    label="Nome"
                    size={formProps?.values?.id ? 7 : 8}
                  />
                  <Col md={2}>
                    <FormGroup className="center">
                      <InlineButton
                        label="Adicionar"
                        disabled={!!formProps.values?.id || !formProps.isValid}
                        onClick={salvarCadastro(false, formProps)}
                      />
                      <InlineButton
                        label="Cadastro completo"
                        className="mt-xs"
                        disabled={!!formProps.values?.id || !formProps.isValid}
                        onClick={salvarCadastro(true, formProps)}
                        icon="fa fa-external-link-alt"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Panel>
    </Modal>
  );
};
