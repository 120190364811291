import imgModule from '@elotech/arc/src/assets/img/modules/administracao-controleprocessos.png';
import imgModuleMain from '@elotech/arc/src/assets/img/modules/administracao-small.png';
import imgModuleNewTheme from '@elotech/arc/src/assets/img/modules/oxy-administrativo.svg';
import { Sidebar } from '@elotech/components';
import { usePermissions } from 'hooks';
import { LABEL_ASSUNTOS, LABEL_PROCESSO, LABEL_PROCESSOS } from 'labels';
import { useSelector } from 'react-redux';
import { keycloakRoles, seletores } from 'roles';
import { getConfigSelector, getUserInfoSelector } from 'state';
import { getEntidadeByPathname, isAuthenticated, unicoUrlParams } from 'utils';

export const AppSidebar = () => {
  const {
    integradoUnico = false,
    usaProcessoExterno,
    usaOuvidoria,
    isCloud,
    cubejsIp
  } = useSelector(getConfigSelector);
  const { hasPermission, hasRole, hasSeletor } = usePermissions();
  const userInfo = useSelector(getUserInfoSelector);

  const isLogged = isAuthenticated();
  const integradoUnicoOrCloud = integradoUnico || isCloud;
  const integradoUnicoWithoutCloud = integradoUnico && !isCloud;
  const notIntegradoUnico = !integradoUnico && !isCloud;
  const integradoUnicoAndCloud = integradoUnico && isCloud;
  const unicoParams = integradoUnico ? unicoUrlParams : '';

  const entidade = getEntidadeByPathname();

  const renderLoggedSidebar = () => {
    return (
      <>
        <Sidebar.Item
          title="Início"
          to="/"
          icon="fa fa-home"
          visible={isCloud ? hasRole(keycloakRoles.home) : true}
        />
        <Sidebar.Group title="Cadastros" icon="fa fa-edit">
          <Sidebar.Item
            title={LABEL_ASSUNTOS}
            to="/assuntos"
            icon="fa fa-align-left"
            visible={hasPermission(keycloakRoles.assunto, seletores.assunto)}
          />

          <Sidebar.Item
            title="Atualização Pessoa"
            to="/atualizacao/pessoas"
            icon="fa fa-users"
            visible={
              notIntegradoUnico &&
              hasPermission(
                keycloakRoles.atualizacaoPessoa,
                seletores.atualizacaoPessoa
              )
            }
          />
          <Sidebar.Item
            title="Bairro"
            to="/bairros"
            icon="fa fa-map-signs"
            visible={
              notIntegradoUnico &&
              hasPermission(keycloakRoles.bairro, seletores.bairro)
            }
          />
          <Sidebar.Item
            title="Caixa"
            to="/caixas"
            icon="fa fa-archive"
            visible={hasPermission(keycloakRoles.caixa, seletores.caixa)}
          />
          <Sidebar.Item
            title="Cidade"
            to="/cidades"
            icon="fa fa-archway"
            visible={
              notIntegradoUnico &&
              hasPermission(keycloakRoles.cidade, seletores.cidade)
            }
          />
          <Sidebar.Item
            title="Coordenadoria"
            to="/coordenadorias"
            icon="fa fa-address-card"
            visible={hasPermission(
              keycloakRoles.coordenadoria,
              seletores.coordenadoria
            )}
          />
          <Sidebar.Item
            title="Dashboards"
            to="/dashboards"
            icon="fa fa-cube"
            visible={
              isLogged &&
              integradoUnico &&
              !!cubejsIp &&
              hasPermission(
                keycloakRoles.cubeJsRead,
                seletores.cubejsDashboards
              )
            }
          />
          <Sidebar.Item
            title="Datas Ignoradas"
            to="/datasignoradas"
            icon="fa fa-calendar"
            visible={hasPermission(
              keycloakRoles.dataIgnorada,
              seletores.dataIgnorada
            )}
          />
          <Sidebar.Item
            title="Diretoria"
            to="/diretorias"
            icon="fa fa-building"
            visible={hasPermission(
              keycloakRoles.diretoria,
              seletores.diretoria
            )}
          />
          <Sidebar.Item
            title="Documentos"
            to="/documentos"
            icon="fa fa-folder-open"
            visible={hasPermission(
              keycloakRoles.documentos,
              seletores.documentos
            )}
          />
          <Sidebar.Item
            title="Gerência"
            to="/gerencias"
            icon="fa fa-id-card"
            visible={hasPermission(keycloakRoles.gerencia, seletores.gerencia)}
          />
          <Sidebar.Item
            title="Grau Sigilo"
            to="/grauSigilos"
            icon="fa fa-shield-alt"
            visible={hasPermission(
              keycloakRoles.grauSigilo,
              seletores.grauSigilo
            )}
          />

          <Sidebar.Item
            title="Local"
            to="/locais"
            icon="fa fa-map-pin"
            visible={hasPermission(keycloakRoles.local, seletores.local)}
          />
          <Sidebar.Item
            title="Logradouro"
            to="/logradouros"
            icon="fa fa-map-pin"
            visible={
              notIntegradoUnico &&
              hasPermission(keycloakRoles.logradouro, seletores.logradouro)
            }
          />
          <Sidebar.Item
            title="Modelos de Impressão"
            to="/modelos-impressao"
            icon="fa fa-file"
            visible={hasPermission(
              keycloakRoles.modeloImpressao,
              seletores.modeloImpressao
            )}
          />
          <Sidebar.Item
            title="Pessoas"
            to="/pessoas"
            icon="fa fa-users"
            visible={
              notIntegradoUnico &&
              hasPermission(keycloakRoles.pessoa, seletores.pessoa)
            }
          />
          <Sidebar.Item
            title="Roteiros"
            to="/roteiros"
            icon="fa fa-directions"
            visible={hasPermission(keycloakRoles.roteiro, seletores.roteiro)}
          />
          <Sidebar.Item
            title="Secretarias"
            to="/secretarias"
            icon="fa fa-building"
            visible={hasPermission(
              keycloakRoles.secretaria,
              seletores.secretaria
            )}
          />
          <Sidebar.Item
            title="Situação"
            to="/situacoes"
            icon="fa fa-info"
            visible={hasPermission(keycloakRoles.situacao, seletores.situacao)}
          />
          <Sidebar.Item
            title="Tipos de Andamento"
            to="/tipos-andamento"
            icon="fa fa-align-left"
            visible={hasPermission(
              keycloakRoles.tipoAndamento,
              seletores.tipoAndamento
            )}
          />
          <Sidebar.Item
            title="Tipos de Notificação"
            to="/tiposnotificacao"
            icon="fa fa-comment"
            visible={hasPermission(
              keycloakRoles.tipoNotificacao,
              seletores.tipoNotificacao
            )}
          />
          <Sidebar.Item
            title="Tipos de Participante"
            to="/tipos-participante"
            icon="fa fa-user-edit"
            visible={hasPermission(
              keycloakRoles.tipoParticipante,
              seletores.tipoParticipante
            )}
          />
          <Sidebar.Item
            title={`Tipos de ${LABEL_PROCESSOS}`}
            to="/tiposprocesso"
            icon="fa fa-list-ul"
            visible={hasPermission(keycloakRoles.tipo, seletores.tipo)}
          />
          <Sidebar.Item
            title="Unidade"
            to="/unidades"
            icon="fa fa-building"
            visible={hasPermission(keycloakRoles.unidade, seletores.unidade)}
          />
          <Sidebar.Item
            title="Unidades Orçamentárias"
            to="/unidadesOrcamentaria"
            icon="fa fa-building"
            visible={hasPermission(
              keycloakRoles.unidadeOrcamentaria,
              seletores.unidadeOrcamentaria
            )}
          />
          <Sidebar.Item
            title="Usuários"
            to="/usuarios?filters==ativo_Igual=true"
            icon="fa fa-user"
            visible={hasPermission(keycloakRoles.usuario, seletores.usuario)}
          />
        </Sidebar.Group>
        {integradoUnicoOrCloud && (
          <Sidebar.Group title="Cadastros Único" icon="fa fa-file-export">
            <Sidebar.Item
              title="Agência"
              icon="fa fa-money-bill"
              to="/unico-agencia"
              visible={
                isCloud &&
                hasPermission(
                  keycloakRoles.unicoCadastros,
                  seletores.unicoCadastros
                )
              }
            />
            <Sidebar.Item
              title="Bairro"
              icon="fa fa-map-signs"
              target="_blank"
              to={`/../unico/bairros${unicoParams}`}
              visible={
                integradoUnicoWithoutCloud &&
                hasSeletor(seletores.unicoCadastros)
              }
            />
            <Sidebar.Item
              title="Bairro"
              icon="fa fa-map-signs"
              to="/unico-bairro"
              visible={
                isCloud &&
                hasPermission(
                  keycloakRoles.unicoCadastros,
                  seletores.unicoCadastros
                )
              }
            />
            <Sidebar.Item
              title="Cidade"
              icon="fa fa-building"
              to="/unico-cidade"
              visible={
                isCloud &&
                hasPermission(
                  keycloakRoles.unicoCadastros,
                  seletores.unicoCadastros
                )
              }
            />
            <Sidebar.Item
              title="Cidade"
              icon="fa fa-map"
              target="_blank"
              to={`/../unico/cidades${unicoParams}`}
              visible={
                integradoUnicoWithoutCloud &&
                hasSeletor(seletores.unicoCadastros)
              }
            />
            <Sidebar.Item
              title="Entidade"
              icon="fa fa-home"
              target="_blank"
              to={`/../unico/entidades${unicoParams}`}
              visible={
                integradoUnicoWithoutCloud &&
                hasSeletor(seletores.unicoCadastros)
              }
            />
            <Sidebar.Item
              title="Legislação"
              icon="fa fa-book-open"
              to="/unico-legislacao"
              visible={
                isCloud &&
                hasPermission(
                  keycloakRoles.unicoCadastros,
                  seletores.unicoCadastros
                )
              }
            />
            <Sidebar.Item
              title="Logradouro"
              icon="fa fa-map-signs"
              to="/unico-logradouro"
              visible={
                isCloud &&
                hasPermission(
                  keycloakRoles.unicoCadastros,
                  seletores.unicoCadastros
                )
              }
            />
            <Sidebar.Item
              title="Logradouro"
              icon="fa fa-map-pin"
              target="_blank"
              to={`/../unico/logradouros${unicoParams}`}
              visible={
                integradoUnicoWithoutCloud &&
                hasSeletor(seletores.unicoCadastros)
              }
            />
            <Sidebar.Item
              title="Pessoa"
              icon="fa fa-users"
              target="_blank"
              to={`/../unico/pessoas${unicoParams}`}
              visible={
                integradoUnicoWithoutCloud &&
                hasSeletor(seletores.unicoCadastros)
              }
            />
            <Sidebar.Item
              title="Pessoa"
              icon="fa fa-users"
              to="/unico-pessoa"
              visible={
                isCloud &&
                hasPermission(
                  keycloakRoles.unicoCadastros,
                  seletores.unicoCadastros
                )
              }
            />
          </Sidebar.Group>
        )}
        <Sidebar.Item
          title="Assinaturas"
          to={`/arquivos-assinatura?filters==situacao_Igual=PENDENTE,usuario.id_Igual=${userInfo?.username}`}
          icon="fa fa-file-signature"
          visible={hasPermission(
            keycloakRoles.assinatura,
            seletores.assinatura
          )}
        />
        <Sidebar.Group title={LABEL_PROCESSOS} icon="fa fa-list-ul">
          <Sidebar.Item
            title={LABEL_PROCESSOS}
            to={`/processos-novo?filters==id.ano_Igual=${new Date().getFullYear()}`}
            icon="fa fa-file"
            visible={hasPermission(keycloakRoles.abertura, seletores.abertura)}
          />
          <Sidebar.Item
            title="Tramitação Dinâmica"
            to="/tramitacao-dinamica"
            icon="fa fa-layer-group"
            visible={hasPermission(
              keycloakRoles.aberturaUpdate,
              seletores.abertura
            )}
          />
          <Sidebar.Item
            title="Anexar Comprovante de Entrega"
            to="/comprovantes-entregas"
            icon="fa fa-clipboard-check"
            visible={hasPermission(
              keycloakRoles.comprovante,
              seletores.comprovante
            )}
          />
          <Sidebar.Item
            title="Avaliar Processos Externos"
            to={`/processos-avaliacao?filters==id.ano_Igual=${new Date().getFullYear()},externo_Igual=true,processoAvaliado_Igual=false`}
            icon="fa fa-check"
            visible={hasPermission(keycloakRoles.abertura, seletores.abertura)}
          />
          <Sidebar.Item
            title="Correção de Fluxo"
            to="/correcaoFluxo"
            icon="fa fa-undo"
            visible={hasPermission(
              keycloakRoles.correcaoFluxo,
              seletores.correcaoFluxo
            )}
          />
          <Sidebar.Item
            title={`Vincular ${LABEL_PROCESSOS}`}
            to="/vincular-processos"
            icon="fa fa-random"
            visible={hasPermission(keycloakRoles.anexar, seletores.anexar)}
          />
          <Sidebar.Item
            title="Controle de Andamentos"
            to="/controle-andamentos"
            icon="fa fa-paper-plane"
          />
        </Sidebar.Group>
        <Sidebar.Group title="Indicadores" icon="fa fa-sort-numeric-up">
          <Sidebar.Item
            title="Processos x Locais"
            to="/indicador-processos-locais"
            icon="fa fa-file-upload"
            visible={hasPermission(
              keycloakRoles.indicadorProcessosLocais,
              seletores.indicadorProcessosLocais
            )}
          />
        </Sidebar.Group>
        <Sidebar.Group title="Relatórios" icon="fa fa-chart-pie">
          <Sidebar.Item
            title="Comprovante de Encaminhamento"
            to="/processos/relatorios/comprovante-encaminhamento"
            icon="fa fa-receipt"
            visible={hasPermission(
              keycloakRoles.relatorioComprovanteEncaminhamento,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title="Estatísticas de Processos"
            to="/processos/relatorios/estatisticas-processos"
            icon="fa fa-list-ul"
            visible={hasPermission(
              keycloakRoles.relatorioEstatisticasProcessos,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title="Etiqueta"
            to="/processos/relatorios/etiqueta"
            icon="fa fa-tag"
            visible={hasPermission(
              keycloakRoles.relatorioEtiqueta,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title={LABEL_PROCESSO}
            to="/processos/relatorios/geral"
            icon="fa fa-copy"
            visible={hasPermission(
              keycloakRoles.relatorioGeral,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title="Relatórios Dinâmicos"
            to="/unico-relatorios-dinamicos"
            icon="fa fa-file-alt"
            visible={
              integradoUnicoAndCloud &&
              hasPermission(
                keycloakRoles.unicoRelatorioDinamico,
                seletores.unicoConsultaDinamica
              )
            }
          />
          <Sidebar.Item
            title="Trâmite"
            to="/processos/relatorios/tramite"
            icon="fa fa-paper-plane"
            visible={hasPermission(
              keycloakRoles.relatorioTramite,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title="Trâmite por Local"
            to="/processos/relatorios/tramite-por-local"
            icon="fa fa-paper-plane"
            visible={hasPermission(
              keycloakRoles.relatorioTramite,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title="Processos a Receber/Encaminhar"
            to="/processos/relatorios/processo-receber-encaminhar"
            icon="fa fa-clipboard-check"
            visible={hasPermission(
              keycloakRoles.relatorioTramite,
              seletores.relatorios
            )}
          />
          <Sidebar.Item
            title="Controle de Emails"
            to={`/processos/relatorios/controle-emails?filters==usuario.id_Contém=${userInfo?.username}`}
            icon="fa fa-clipboard-check"
            visible={hasPermission(
              keycloakRoles.relatorioTramite,
              seletores.relatorios
            )}
          />
        </Sidebar.Group>
        <Sidebar.Item
          title="Consultas Dinâmicas"
          to="/unico-consultas-dinamicas"
          icon="fa fa-magic"
          visible={
            integradoUnicoAndCloud &&
            hasPermission(
              keycloakRoles.unicoConsultaDinamicaRead,
              seletores.unicoConsultaDinamica
            )
          }
        />
        <Sidebar.Group title="Modelos de Dados" icon="fa fa-file-alt">
          <Sidebar.Item
            title="Modelos"
            to="/modelos-documentos?filters==ativo_Igual=true"
            icon="fa fa-table"
            visible={hasPermission(
              keycloakRoles.modelosDocumentos,
              seletores.modelosDocumentos
            )}
          />
          <Sidebar.Item
            title="Variáveis/Sqls"
            to="/variaveis"
            icon="fa fa-list"
            visible={hasPermission(
              keycloakRoles.variaveis,
              seletores.variaveis
            )}
          />
        </Sidebar.Group>
        <Sidebar.Group title="Configurações" icon="fa fa-cogs">
          <Sidebar.Item
            title="Integração Processos Tributos"
            to="/enviar-processos"
            icon="fa fa-file"
            visible={hasPermission(
              keycloakRoles.integracaoTributos,
              seletores.integracaoTributos
            )}
          />
          <Sidebar.Item
            title="Parametrizações de integração"
            to="/parametrizacoes-integracao"
            icon="fa fa-random"
            visible={hasPermission(
              keycloakRoles.parametrosIntegracaoRead,
              seletores.parametros
            )}
          />
          <Sidebar.Item
            title="Parâmetros"
            to="/parametros"
            icon="fa fa-tasks"
            visible={hasPermission(
              keycloakRoles.parametros,
              seletores.parametros
            )}
          />
        </Sidebar.Group>
        <Sidebar.Group title="Configurações Assinaturas" icon="fa fa-signature">
          <Sidebar.Item
            title="Assinantes"
            to="/assinantes"
            icon="fa fa-users"
            visible={hasPermission(
              keycloakRoles.assinante,
              seletores.assinante
            )}
          />
          <Sidebar.Item
            title="Configuração Assinatura"
            to="/assinatura-configuracao"
            icon="fa fa-file-signature"
            visible={hasPermission(
              keycloakRoles.assinaturaConfig,
              seletores.assinaturaConfig
            )}
          />
          <Sidebar.Item
            title="Fluxos de Assinaturas"
            to="/fluxos-assinaturas"
            icon="fa fa-link"
            visible={hasPermission(
              keycloakRoles.fluxoAssinatura,
              seletores.fluxoAssinatura
            )}
          />
          <Sidebar.Item
            title="Grupos Assinantes"
            to="/grupos-assinantes"
            icon="fa fa-object-group"
            visible={hasPermission(
              keycloakRoles.grupoAssinante,
              seletores.grupoAssinante
            )}
          />
        </Sidebar.Group>
      </>
    );
  };

  const renderPublicSidebar = () => {
    const entidadeUrl = entidade ? `/${entidade}` : '';

    return (
      <>
        <Sidebar.Item
          title="Abertura de Processo"
          to={`${entidadeUrl}/aberturaProcessoExterno`}
          icon="fa fa-file"
          visible={!isLogged && usaProcessoExterno}
        />
        <Sidebar.Item
          title="Consulta Autenticidade"
          to={`${entidadeUrl}/consulta-autenticidade`}
          icon="fa fa-download"
        />
        <Sidebar.Item
          title="Consulta Processos"
          to={`${entidadeUrl}/consultaProcesso`}
          icon="fa fa-search"
        />
        <Sidebar.Item
          title="Consulta Processos por Chave"
          to={`${entidadeUrl}/consulta-processo-chave`}
          icon="fa fa-key"
        />
        <Sidebar.Item
          title="Ouvidoria"
          to={`${entidadeUrl}/ouvidoria`}
          icon="fa fa-file"
          visible={!isLogged && usaOuvidoria}
        />
      </>
    );
  };

  return (
    <Sidebar
      title="Protocolo"
      imgModule={imgModule}
      imgModuleMain={imgModuleMain}
      imgModuleNewTheme={imgModuleNewTheme}
    >
      {isLogged ? renderLoggedSidebar() : renderPublicSidebar()}
    </Sidebar>
  );
};
