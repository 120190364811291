export enum ClassificacaoOperacaoEnum {
  COMODATO = 'COMODATO',
  DEPRECIACAO = 'DEPRECIACAO',
  BAIXA = 'BAIXA',
  ESTORNO = 'ESTORNO',
  INVENTARIO = 'INVENTARIO',
  REAVALIACAO = 'REAVALIACAO',
  CORRECAO = 'CORRECAO',
  OUTROS = 'OUTROS'
}

export const classificacaoOperacaoFormatada = {
  COMODATO: 'Comodato',
  DEPRECIACAO: 'Depreciação',
  BAIXA: 'Baixa',
  ESTORNO: 'Estorno',
  INVENTARIO: 'Inventário',
  REAVALIACAO: 'Reavaliação',
  CORRECAO: 'Correção',
  OUTROS: 'Outros'
};
