export enum MimeType {
  APPLICATION_CSV = 'application/csv',
  APPLICATION_MSWORD = 'application/msword',
  APPLICATION_JSON = 'application/json',
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_APPLICATION = 'application/application',
  APPLICATION_VND = 'application/vnd',
  APPLICATION_XML = 'application/xml',
  APPLICATION_ZIP = 'application/zip',
  APLICATION_OCTET = 'aplication/octet',
  APLICATION_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TEXT_PLAIN = 'text/plain',
  IMAGE_JPEG = 'image/jpeg',
  TEXT_HTML = 'text/html',
  IMAGE_PNG = 'image/png',
  APLICATION_XLS = 'application/vnd.ms-excel',
  APLICATION_XLSX = ' application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_RAR = 'application/vnd.rar',
  APPLICATION_ODT = 'application/vnd.oasis.opendocument.text',
  APPLICATION_ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  APPLICATION_OTS = 'application/vnd.oasis.opendocument.spreadsheet-template',
  IMAGE_BMP = 'image/bmp',
  IMAGE_GIF = 'image/gif',
  APPLICATION_PPT = 'application/vnd.ms-powerpoint',
  APPLICATION_PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  APPLICATION_RTF = 'application/rtf'
}
