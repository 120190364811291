import {
  BasicInput,
  Col,
  FormikAutocomplete,
  FormikCheckBox,
  Row,
  Yup
} from '@elotech/components';
import { FormikTinyMCE, InlineButton } from 'common/components';
import { getUrl } from 'common/utils';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  ModeloDadosService,
  PessoaService,
  TipoAndamentoService
} from 'service';
import { getConfigSelector } from 'state';
import { TipoAndamentoEnum } from 'types';
import { unicoUrl, unicoUrlParams } from 'utils';

const initialValues = {
  pessoa: undefined,
  tipoAndamento: undefined,
  mensagem: '',
  obrigaVisualizacao: true
};

function validationSchema() {
  return Yup.object().shape({
    pessoa: Yup.object().required('Pessoa é obrigatória'),
    emailAlternativo: Yup.string()
      .email('Email Inválido')
      .test('required', 'Email Obrigatório', function (value) {
        if (this.parent.pessoa?.email) {
          return true;
        }

        return value;
      }),
    tipoAndamento: Yup.object().required('Tipo Andamento é obrigatório')
  });
}

const ProcessoObservadorExternoForm = ({ onCancel, onAdd }) => {
  const formikRef = useRef(null);

  const config = useSelector(getConfigSelector);

  const onSubmit = (values, actions) => {
    if (values.emailAlternativo === values.pessoa?.email) {
      values.emailAlternativo = null;
    }

    onAdd(values);
    actions.resetForm(initialValues);
  };

  const onCreateRequerente = () => {
    const url =
      config.integradoUnico || config.isCloud
        ? `${unicoUrl}/pessoas/new${unicoUrlParams}`
        : getUrl('protocolo', '/pessoa/new');

    window.open(url, '_blank');

    return Promise.resolve();
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        ref={formikRef}
      >
        {formProps => (
          <Form>
            <Row>
              <FormikAutocomplete
                size={2}
                fast={false}
                name="pessoa"
                label="Pessoa"
                onCreateValue={onCreateRequerente}
                newValueLabel="Adicionar Pessoa"
                onSearch={PessoaService.findAllAutocomplete}
                getOptionLabel={r => r.nome}
                onItemSelected={(_, value) => {
                  formProps.setFieldValue('emailAlternativo', value?.email);
                }}
              />
              <FormikAutocomplete
                name="tipoAndamento"
                label="Tipo Andamento"
                getOptionLabel={s => `${s.id} - ${s.descricao}`}
                onSearch={search =>
                  TipoAndamentoService.findAllAutocomplete(
                    search,
                    formProps?.values?.tipoAndamento?.id,
                    null,
                    `tipo==${TipoAndamentoEnum.EMAIL}`
                  )
                }
                size={2}
              />
              <FormikAutocomplete
                name="modelo"
                label="Modelo"
                getOptionLabel={md => md.nome}
                onSearch={ModeloDadosService.findAllAutocomplete}
                size={2}
                onItemSelected={(formProps, value) => {
                  if (value?.id) {
                    ModeloDadosService.findTemplate(value.id).then(({ data }) =>
                      formProps.setFieldValue('mensagem', data)
                    );
                  }
                }}
              />
              <FormikCheckBox
                name="obrigaVisualizacao"
                label="Obriga Visualização"
                className="mt-xs"
                size={3}
              />
              <Col sm={1} md={1} className="mt-xs">
                <FormGroup>
                  <InlineButton
                    label="Adicionar"
                    onClick={formProps.submitForm}
                  />
                </FormGroup>
              </Col>
              <Col sm={1} md={1} className="mt-xs">
                <FormGroup>
                  <InlineButton
                    label="Cancelar"
                    color="negative"
                    onClick={() => {
                      formProps.resetForm(initialValues);
                      onCancel();
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            {formProps.values.pessoa && (
              <Row>
                <BasicInput
                  name="emailAlternativo"
                  label="Email"
                  hint="Este email será utilizado somente para o envio de email ao observador, sua edição não altera o email da pessoa selecionada."
                  onBlur={() => {
                    const { pessoa, emailAlternativo } =
                      formProps?.values || {};

                    if (pessoa?.email && !emailAlternativo) {
                      formProps.setFieldValue('emailAlternativo', pessoa.email);
                    }
                  }}
                />
              </Row>
            )}
            <Row>
              <FormikTinyMCE name="mensagem" label="Mensagem" size={12} />
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProcessoObservadorExternoForm;
