import {
  BasicInput,
  DateUtils,
  FormikAutocomplete,
  FormikCheckBox,
  FormikInputDate,
  FormikInputDateTime,
  FormikInputInteger,
  Row,
  Yup
} from '@elotech/components';
import { FormikTinyMCE, InlineButton, alert } from 'common/components';
import { formataCpfOuCnpj } from 'common/utils';
import { addDays, format } from 'date-fns';
import { Form, Formik } from 'formik';
import { LABEL_ASSUNTO } from 'labels';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  AssuntoService,
  DataIgnoradaService,
  LocalService,
  PessoaService,
  ProcessoAndamentoService,
  SituacaoService,
  TipoAndamentoService,
  UsuarioService
} from 'service';
import { getUserInfoSelector } from 'state';
import { TipoAndamentoEnum } from 'types';

export const ProcessoAndamentosForm = ({ onClose, onAdd, value, processo }) => {
  const { userInfo } = useSelector(state => ({
    userInfo: getUserInfoSelector(state)
  }));

  let previousDataInicio = null;

  const isTipoAlteraRequerente =
    value?.tipoAndamento?.tipo === TipoAndamentoEnum.ALTERAREQUERENTE;

  const initialValueAlteraRequerente = isTipoAlteraRequerente
    ? value?.alteracaoRequerente || {
        requerenteOld: processo?.pessoa,
        requerenteNew: null
      }
    : value?.alteracaoRequerente;

  const initialValues = {
    id: value?.id,
    numeroAndamento: value?.numeroAndamento || '',
    descricao: value?.descricao || '',
    usuario: userInfo.username,
    usuarioNome: userInfo.nome,
    dataHora: value?.dataHora || DateUtils.getCurrentDateTime(),
    modulo: 'OXY_PROCESSOS',
    solicitacaoRequerente: value?.solicitacaoRequerente || false,
    finalizado: value?.finalizado || false,
    tramite: value?.tramite || undefined,
    respostaRequerente: value?.respostaRequerente || '',
    dataHoraResposta: value?.dataHoraResposta,
    tipoAndamento: value?.tipoAndamento || undefined,
    dataInicio: value?.dataInicio || '',
    dataTermino: value?.dataTermino || '',
    dataFinalizacao: value?.dataFinalizacao || '',
    diasSuspensao: value?.diasSuspensao,
    assuntoOld: value?.assuntoOld || processo?.assunto || undefined,
    assuntoNew: value?.assuntoNew || undefined,
    local: value?.local || undefined,
    usuarioResponsavel: value?.usuarioResponsavel || undefined,
    resposta: value?.resposta || '',
    definirSigiloso: value?.definirSigiloso || processo.sigiloso,
    bloqueioAnexoExterno: value?.bloqueioAnexoExterno,
    situacaoProcessoNew: undefined,
    alteracaoRequerente: initialValueAlteraRequerente
  };

  const handleSubmit = async (values, actions) => {
    if (!initialValues.finalizado && values.finalizado) {
      const value = await alert({
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Finalizar',
        cancelButtonText: 'Cancelar',
        text: 'Tem certeza que deseja finalizar o andamento? Essa ação não pode ser desfeita.',
        onConfirm: () => true
      });

      if (!value) {
        return false;
      }
    }

    onAdd(values);

    actions.resetForm(initialValues);
  };

  const adjustDataTermino = ({ values, setFieldValue }) => {
    const { controlaPrazo, prazoBase } = values?.tipoAndamento || {};
    const { dataInicio } = values || {};

    if (!dataInicio || !controlaPrazo || !prazoBase) {
      return values?.dataTermino;
    }

    const dataTerminoNovo = addDays(new Date(dataInicio), prazoBase);
    let dataTerminoFormatted;

    if (dataInicio !== previousDataInicio) {
      previousDataInicio = dataInicio;
      DataIgnoradaService.retornaSugestaoDataTipoAndamento(
        values.tipoAndamento.id,
        format(addDays(dataTerminoNovo, 1), 'yyyy-MM-dd')
      ).then(({ data }) => {
        dataTerminoFormatted = format(addDays(new Date(data), 1), 'yyyy-MM-dd');
        setFieldValue('dataTermino', dataTerminoFormatted);
      });
    }
  };

  const adjustFinalizado = ({ values, setFieldValue }) => {
    const { finalizado, dataFinalizacao } = values || {};

    if (!dataFinalizacao && finalizado) {
      setFieldValue('dataFinalizacao', DateUtils.getCurrentDateTime());
    } else if (!finalizado && initialValues?.finalizado) {
      setFieldValue('finalizado', true);
    } else if (!!dataFinalizacao && !finalizado) {
      setFieldValue('dataFinalizacao', '');
    }
  };

  const onChangeTipoAndamento = (formProps, value) => {
    if (value?.id) {
      ProcessoAndamentoService.getNumeroRecomendado(value.id).then(
        ({ data }) => {
          formProps.setFieldValue('numeroAndamento', data);
        }
      );
    }

    if (value?.padraoFinalizado) {
      formProps.setFieldValue('finalizado', true);
    }

    value?.tipo === TipoAndamentoEnum.BLOQUEIOANEXOEXTERNO
      ? formProps.setFieldValue(
          'bloqueioAnexoExterno',
          processo.bloqueioAnexoExterno
        )
      : formProps.setFieldValue('bloqueioAnexoExterno', false);

    if (value?.tipo === TipoAndamentoEnum.ALTERAREQUERENTE) {
      formProps.setFieldValue(
        'alteracaoRequerente',
        initialValues.alteracaoRequerente || {
          requerenteOld: processo?.pessoa
        }
      );
    } else {
      formProps.setFieldValue('alteracaoRequerente', null);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          numeroAndamento: Yup.number().required('Número é obrigatório'),
          descricao: Yup.string().required('Descrição é obrigatória'),
          tipoAndamento: Yup.object().shape({
            controlaPrazo: Yup.boolean(),
            prazoBase: Yup.number()
          }),
          dataInicio: Yup.date().test(
            'dataInicioRequired',
            'Data Início é obrigatória',
            function (value) {
              if (
                this.parent.tipoAndamento?.controlaPrazo &&
                this.parent.tipoAndamento?.tipo !==
                  TipoAndamentoEnum.PREVISAOCONCLUSAO
              ) {
                return value;
              }

              return true;
            }
          ),
          dataTermino: Yup.date()
            .test(
              'dataTerminoRequired',
              'Data Término é obrigatória',
              function (value) {
                if (
                  this.parent.tipoAndamento?.controlaPrazo ||
                  this.parent.tipoAndamento?.tipo ===
                    TipoAndamentoEnum.PREVISAOCONCLUSAO
                ) {
                  return value;
                }

                return true;
              }
            )
            .test(
              'dataTerminoMin',
              'Data Término não pode ser anterior a Data Início',
              function (value) {
                if (
                  this.parent.tipoAndamento?.controlaPrazo &&
                  this.parent.tipoAndamento?.tipo !==
                    TipoAndamentoEnum.PREVISAOCONCLUSAO
                ) {
                  return new Date(value) >= new Date(this.parent.dataInicio);
                }

                return true;
              }
            ),
          diasSuspensao: Yup.number().test(
            'diasSuspensao',
            'Dias Suspensão é obrigatório',
            function (value) {
              if (
                this.parent.tipoAndamento?.tipo === TipoAndamentoEnum.SUSPENSAO
              ) {
                return value;
              }

              return true;
            }
          ),
          situacaoProcessoNew: Yup.object().test(
            'situacaoProcessoNewRequired',
            'Situação é obrigatória',
            function (value) {
              if (
                this.parent.tipoAndamento?.tipo ===
                TipoAndamentoEnum.ALTERASITUACAO
              ) {
                return value;
              }

              return true;
            }
          ),
          finalizado: Yup.boolean().test(
            'finalizadoRequired',
            'Finalizado é obrigatório',
            function (value) {
              if (
                this.parent.tipoAndamento?.tipo ===
                  TipoAndamentoEnum.ALTERASITUACAO &&
                !this.parent.finalizado
              ) {
                return value;
              }

              return true;
            }
          ),
          alteracaoRequerente: Yup.object({
            requerenteOld: Yup.object().nullable(),
            requerenteNew: Yup.object().nullable()
          })
            .nullable()
            .test(
              'isValidRequerente',
              'Requerente é inválido',
              function (value) {
                if (
                  this.parent.tipoAndamento?.tipo !==
                  TipoAndamentoEnum.ALTERAREQUERENTE
                ) {
                  return true;
                }

                const requerentePath = `${this.path}.requerenteNew`;

                if (!value || !value.requerenteNew) {
                  return this.createError({
                    path: requerentePath,
                    message: 'Requerente é obrigatório'
                  });
                }

                const requerenteOldId = value.requerenteOld?.id;

                if (value.requerenteNew.id === requerenteOldId) {
                  return this.createError({
                    path: requerentePath,
                    message: 'Requerente deve ser diferente do anterior'
                  });
                }

                return true;
              }
            )
        })}
      >
        {formProps => {
          adjustFinalizado(formProps);

          const tipoAndamento = formProps.values?.tipoAndamento;

          return (
            <Form>
              <Row>
                <FormikInputDateTime
                  name="dataHora"
                  label="Data/Hora"
                  size={3}
                  disabled
                />
                <BasicInput
                  name="usuarioNome"
                  label="Usuário Registro"
                  size={3}
                  disabled
                />
                <FormikAutocomplete
                  name="tipoAndamento"
                  label="Tipo Andamento"
                  getOptionLabel={s => `${s.id} - ${s.descricao}`}
                  onSearch={search =>
                    TipoAndamentoService.findAllAutocomplete(
                      search,
                      tipoAndamento?.id
                    )
                  }
                  size={3}
                  onItemSelected={onChangeTipoAndamento}
                />
                <FormikInputInteger
                  name="numeroAndamento"
                  label="Número"
                  size={3}
                />
              </Row>
              {tipoAndamento?.tipo === TipoAndamentoEnum.ALTERACAOCATEGORIA && (
                <Row>
                  <FormikAutocomplete
                    name="assuntoOld"
                    label={LABEL_ASSUNTO}
                    getOptionLabel={a => `${a.id} - ${a.descricao}`}
                    onSearch={AssuntoService.findAllAutocomplete}
                    size={6}
                    disabled
                  />
                  <FormikAutocomplete
                    name="assuntoNew"
                    label={LABEL_ASSUNTO}
                    getOptionLabel={a => `${a.id} - ${a.descricao}`}
                    onSearch={AssuntoService.findAllAutocomplete}
                    size={6}
                  />
                </Row>
              )}
              {tipoAndamento?.tipo === TipoAndamentoEnum.COMUNICACAOINTERNA && (
                <>
                  <Row>
                    <FormikAutocomplete
                      name="local"
                      label="Local"
                      getOptionLabel={loc => loc.descricao}
                      onSearch={LocalService.findAllLocaisAutocomplete}
                      size={6}
                    />
                    <FormikAutocomplete
                      name="usuarioResponsavel"
                      label="Usuário Responsável"
                      getOptionLabel={usu => usu.nome}
                      onSearch={UsuarioService.findAllAutocomplete}
                      size={6}
                    />
                  </Row>
                  <Row>
                    <BasicInput name="resposta" label="Resposta" size={12} />
                  </Row>
                </>
              )}
              <Row>
                {tipoAndamento?.tipo ===
                  TipoAndamentoEnum.DEFINICAOSIGILOSO && (
                  <FormikCheckBox
                    size={3}
                    className="mt-xs"
                    label="Definir Sigiloso"
                    name="definirSigiloso"
                    id="definirSigiloso"
                  />
                )}
                <FormikCheckBox
                  size={3}
                  className="mt-xs"
                  label="Solicitação ao Requerente"
                  name="solicitacaoRequerente"
                  id="solicitacaoRequerente"
                />
                <FormikCheckBox
                  size={2}
                  className="mt-xs"
                  label="Finalizado"
                  name="finalizado"
                  id="finalizado"
                />
                <FormikInputDateTime
                  className="mt-xs"
                  fast={false}
                  disabled
                  size={3}
                  name="dataFinalizacao"
                  label="Finalizado em"
                />
                {tipoAndamento?.tipo ===
                  TipoAndamentoEnum.BLOQUEIOANEXOEXTERNO && (
                  <FormikCheckBox
                    size={2}
                    className="mt-xs"
                    label="Bloqueia Anexos Externos"
                    name="bloqueioAnexoExterno"
                    id="bloqueioAnexoExterno"
                  />
                )}
                {tipoAndamento?.controlaPrazo &&
                  tipoAndamento?.tipo !==
                    TipoAndamentoEnum.PREVISAOCONCLUSAO && (
                    <FormikInputDate
                      fast={false}
                      name="dataInicio"
                      label="Data Início"
                      size={2}
                      onChange={adjustDataTermino(formProps)}
                    />
                  )}
                {(tipoAndamento?.controlaPrazo ||
                  tipoAndamento?.tipo ===
                    TipoAndamentoEnum.PREVISAOCONCLUSAO) && (
                  <FormikInputDate
                    fast={false}
                    name="dataTermino"
                    size={2}
                    label={`Data Término${
                      tipoAndamento?.prazoBase &&
                      tipoAndamento?.tipo !==
                        TipoAndamentoEnum.PREVISAOCONCLUSAO
                        ? ` - ${tipoAndamento?.prazoBase} dia(s)`
                        : ''
                    }`}
                  />
                )}
                {tipoAndamento?.tipo === TipoAndamentoEnum.SUSPENSAO && (
                  <FormikInputInteger
                    name="diasSuspensao"
                    label="Dias Suspensão"
                    size={2}
                  />
                )}
                {tipoAndamento?.tipo === TipoAndamentoEnum.ALTERASITUACAO && (
                  <FormikAutocomplete
                    size={4}
                    name="situacaoProcessoNew"
                    label="Situação"
                    onSearch={SituacaoService.findAllAutocomplete}
                    getOptionLabel={s => `${s.id} - ${s.descricao}`}
                  />
                )}
                {tipoAndamento?.tipo === TipoAndamentoEnum.TAD && (
                  <FormikCheckBox
                    size={4}
                    className="mt-xs"
                    name="tad"
                    label="Termo de Ajustamento de Conduta Disciplinar (TAD)"
                  />
                )}
                {tipoAndamento?.tipo === TipoAndamentoEnum.DATAACORDO && (
                  <FormikInputDate
                    size={4}
                    name="dataAcordo"
                    label="Data de Acordo"
                  />
                )}
                {tipoAndamento?.tipo === TipoAndamentoEnum.PRAZO && (
                  <FormikInputInteger
                    size={4}
                    name="prazo"
                    label="Prazo (dias)"
                  />
                )}
              </Row>

              {tipoAndamento?.tipo === TipoAndamentoEnum.ALTERAREQUERENTE && (
                <Row>
                  <FormikAutocomplete
                    size={4}
                    fast={false}
                    name="alteracaoRequerente.requerenteOld"
                    label="Requerente Anterior"
                    onSearch={PessoaService.findAllRequerentes}
                    getOptionLabel={r =>
                      `${formataCpfOuCnpj(r.cnpjCpf)} - ${r.nome}`
                    }
                    disabled
                  />

                  <FormikAutocomplete
                    size={4}
                    fast={false}
                    name="alteracaoRequerente.requerenteNew"
                    label="Novo Requerente"
                    onSearch={PessoaService.findAllRequerentes}
                    getOptionLabel={r =>
                      `${formataCpfOuCnpj(r.cnpjCpf)} - ${r.nome}`
                    }
                  />
                </Row>
              )}

              <Row>
                <FormikTinyMCE
                  size={12}
                  label="Descrição"
                  name="descricao"
                  id="editor-desc-andamento"
                />
              </Row>
              <Row>
                <div className="pull-right mb-md">
                  <FormGroup>
                    <InlineButton
                      label={value ? 'Salvar' : 'Adicionar'}
                      type="submit"
                    />
                    <InlineButton
                      color="negative"
                      label="Cancelar"
                      onClick={onClose}
                    />
                  </FormGroup>
                </div>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
