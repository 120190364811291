import {
  Col,
  FormikAutocomplete,
  FormikCheckBox,
  FormikInputInteger,
  FormikTextArea,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { InlineButton, Label } from 'common/components';
import { Formik } from 'formik';
import { LABEL_ASSUNTOS, LABEL_PARECER, LABEL_PARECERES } from 'labels';
import { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  AssuntoService,
  CaixaService,
  LocalService,
  ParametroService,
  ProcessoTaxaService,
  SituacaoService,
  TributosService
} from 'service';
import { getConfigSelector } from 'state';

import { REALIZA_FECHAMENTO_GERAL_ARQUIVAMENTO } from '../../parametros/Parametros';
import { TramiteArquivo } from './TramiteArquivo';
import { TramitesProcessosPareceres } from './TramitesProcessosPareceres';
import { getLocalByRoteiro } from './TramiteUtils';

export const TramiteArquivarForm = ({
  processo,
  situacao,
  onTramitar,
  hasProcessoPai,
  processos = [],
  attProcessos,
  paramProcessoMp,
  ocultaProcFis = false,
  ocultaArquivo = false
}) => {
  const formikRef = useRef(null);
  const [situacaoInicial, setSituacaoInicial] = useState();
  const { config } = useSelector(state => ({
    config: getConfigSelector(state)
  }));
  const [statusBoleto, setStatusBoleto] = useState({});
  const [integradoTributos, setIntegradoTributos] = useState(false);

  const validationSchema = Yup.object().shape({
    local: Yup.object().required('Local Destino é obrigatório'),
    situacao: Yup.object()
      .required('Situacão é obrigatória')
      .test(
        'pagamentoPendente',
        'Situação não permitida. Há pagamento(s) de taxa(s) realizado(s).',
        function (value) {
          if (
            value &&
            value.pagamentoPendente &&
            integradoTributos &&
            statusBoleto.boletoPago
          ) {
            return false;
          }
          return true;
        }
      )
  });

  useEffect(() => {
    if (!formikRef.current.state.values.situcao) {
      formikRef.current?.setFieldValue('situacao', situacao);
    }
  }, [situacao]);

  useEffect(() => {
    SituacaoService.findAllAutocompleteArquivamento()
      .then(({ data }) => {
        if (data.content.length === 1) {
          setSituacaoInicial(data.content[0]);
        }
        if (situacao && situacao.arquivamento) {
          setSituacaoInicial(situacao);
        }
      })
      .then(
        ParametroService.findParametro(
          REALIZA_FECHAMENTO_GERAL_ARQUIVAMENTO
        ).then(({ data }) => {
          formikRef.current?.setFieldValue('fechado', data.valor === 'S');
        })
      );
  }, []);

  useEffect(() => {
    if (processo) {
      formikRef.current?.setFieldValue(
        'local',
        getLocalByRoteiro(processo, 'arquivar')
      );
      formikRef.current?.setFieldValue('assunto', processo.assunto);
    }
  }, [processo]);

  useEffect(() => {
    TributosService.possuiIntegracao().then(({ data }) =>
      setIntegradoTributos(data)
    );
  }, []);

  useEffect(() => {
    if (processo?.id) {
      ProcessoTaxaService.verificarStatusPagamento(
        processo.id,
        processo.entidade
      ).then(({ data }) => setStatusBoleto(data));
    }
  }, [integradoTributos, processo?.id, processo?.entidade]);

  return (
    <Formik
      ref={formikRef}
      initialValues={{
        situacao: situacaoInicial,
        local: undefined,
        caixa: undefined,
        notaPrivada: false,
        quantidadeFolhas: undefined,
        parecer: '',
        arquivos: [],
        processoFisico: false,
        fechado: false,
        processos
      }}
      onSubmit={onTramitar}
      validationSchema={validationSchema}
    >
      {formProps => {
        return (
          <>
            <Row>
              <FormikAutocomplete
                size={6}
                name="situacao"
                label="Situação"
                onSearch={SituacaoService.findAllAutocompleteArquivamento}
                getOptionLabel={s => `${s.id} - ${s.descricao}`}
              />
              <FormikAutocomplete
                size={6}
                name="local"
                label="Local Destino"
                disabled={processo?.assunto?.controlaTramitacao}
                getOptionLabel={l => `${l.id} - ${l.descricao}`}
                onSearch={LocalService.findAllLocaisAutocomplete}
              />
            </Row>
            <Row>
              {!config?.isProcessoMp && (
                <FormikAutocomplete
                  size={6}
                  name="caixa"
                  label="Caixa"
                  onSearch={CaixaService.findAllAutocomplete}
                  getOptionLabel={c => `${c.codigo} - ${c.descricao}`}
                  onItemSelected={(form, value) => {
                    if (!value) {
                      form.setFieldValue('caixa', undefined);
                    }

                    form.setFieldValue('caixa', value);
                  }}
                />
              )}
              <FormikInputInteger
                size={2}
                name="quantidadeFolhas"
                label="Quantidade Folhas"
              />
              {!!processo && (
                <FormikAutocomplete
                  size={4}
                  name="assunto"
                  label={LABEL_ASSUNTOS}
                  getOptionLabel={l => `${l.id} - ${l.descricao}`}
                  onSearch={AssuntoService.findAllAutocomplete}
                  onItemSelected={(form, value) =>
                    form.setFieldValue('assunto', value)
                  }
                />
              )}
            </Row>
            <Row>
              {!paramProcessoMp && (
                <FormikCheckBox
                  size={3}
                  className="mt-xs"
                  name="notaPrivada"
                  label="Nota Privada"
                />
              )}
              {processos.length === 0 && !ocultaProcFis && (
                <FormikCheckBox
                  size={3}
                  className="mt-xs"
                  name="processo.processoFisico"
                  label="Processo Físico"
                />
              )}
              <FormikCheckBox
                size={3}
                className="mt-xs"
                name="fechado"
                label="Fechamento Completo"
              />
            </Row>
            {!ocultaArquivo && (
              <Row>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label>Arquivos</Label>
                    <TramiteArquivo formProps={formProps} />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <SectionTitle>{LABEL_PARECERES}</SectionTitle>
            <Row>
              <FormikTextArea size={12} name="parecer" label={LABEL_PARECER} />
            </Row>
            {processos.length > 0 && (
              <TramitesProcessosPareceres
                processos={processos}
                attProcessos={attProcessos}
                paramProcessoMp={paramProcessoMp}
                ocultaProcFis={ocultaProcFis}
              />
            )}
            <Row className="mt-xs">
              <Col md={12} className="right ml-xs">
                <InlineButton
                  label="Arquivar"
                  disabled={hasProcessoPai}
                  onClick={formProps.submitForm}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};
