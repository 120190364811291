import {
  Col,
  FormikAutocomplete,
  FormikCheckBox,
  FormikTextArea,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { InlineButton, Label } from 'common/components';
import { Formik } from 'formik';
import { LABEL_PARECER, LABEL_PARECERES } from 'labels';
import { useEffect, useRef } from 'react';
import { FormGroup } from 'react-bootstrap';
import { SituacaoService } from 'service';

import { TramiteArquivo } from './TramiteArquivo';
import { TramitesProcessosPareceres } from './TramitesProcessosPareceres';

const validationSchema = Yup.object().shape({
  situacao: Yup.object().required('Situação é obrigatória')
});

export const TramiteReceberForm = ({
  situacao,
  onTramitar,
  hasProcessoPai,
  processos = [],
  processo,
  paramProcessoMp,
  ocultaProcFis = false,
  ocultaArquivo = false
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current.state.values.situcao) {
      ref.current?.setFieldValue('situacao', situacao);
    }
  }, [situacao]);

  return (
    <Formik
      initialValues={{
        situacao,
        parecer: '',
        arquivos: [],
        notaPrivada: false,
        processoFisico: false,
        processo,
        processos
      }}
      ref={ref}
      onSubmit={onTramitar}
      validationSchema={validationSchema}
    >
      {formProps => {
        return (
          <>
            <Row>
              <FormikAutocomplete
                size={paramProcessoMp ? 10 : 8}
                name="situacao"
                label="Situação"
                onSearch={SituacaoService.findAllAutocomplete}
                getOptionLabel={s => `${s.id} - ${s.descricao}`}
              />
              {!paramProcessoMp && (
                <FormikCheckBox
                  size={2}
                  className="mt-xs"
                  name="notaPrivada"
                  label="Nota Privada"
                />
              )}
              {processos.length === 0 && !ocultaProcFis && (
                <FormikCheckBox
                  size={2}
                  className="mt-xs"
                  name="processo.processoFisico"
                  label="Processo Físico"
                />
              )}
            </Row>
            {!ocultaArquivo && (
              <Row>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label>Arquivos</Label>
                    <TramiteArquivo formProps={formProps} />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <SectionTitle>{LABEL_PARECERES}</SectionTitle>
            <Row>
              <FormikTextArea size={12} name="parecer" label={LABEL_PARECER} />
            </Row>
            {processos.length > 0 && (
              <TramitesProcessosPareceres
                processos={processos}
                paramProcessoMp={paramProcessoMp}
                ocultaProcFis={ocultaProcFis}
              />
            )}
            <Row className="mt-xs">
              <Col md={12} className="right ml-xs">
                <InlineButton
                  label="Receber"
                  disabled={hasProcessoPai}
                  onClick={formProps.submitForm}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};
