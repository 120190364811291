import defaultAxios from 'axios';
import {
  TransProgressKind,
  transientRequest,
  useTransProgress
} from 'common/components';
import { getContentType } from 'common/utils';
import { axios } from 'libs';

import * as ArquivoService from './ArquivoService';

const resource = '/processos-arquivos';

export const analisar = analise => {
  return axios.put(`${resource}/analisar`, analise);
};

export const fileToArquivoDocumento = async (file, isFileCloud) => {
  if (file.arquivoDocumento) {
    return file.arquivoDocumento;
  }

  const fileName = file.nome;

  const endpoint = `${resource}${isFileCloud ? '/cloud' : ''}/arquivo/upload`;
  const formData = new FormData();
  formData.append('file', file);

  if (!isFileCloud) {
    return axios
      .post(
        endpoint,
        formData,
        useTransProgress(fileName, TransProgressKind.UPLOAD)
      )
      .then(
        ({ data }) =>
          (file.arquivoDocumento = {
            ...data,
            documento: file.documento?.id
          })
      );
  }

  const contentType = getContentType(fileName);

  return ArquivoService.getUrlUpload(fileName, contentType).then(
    ({ data: arquivoUrl }) =>
      defaultAxios
        .put(arquivoUrl.urlAssinada, formData.get('file'), {
          headers: {
            'Content-Type': contentType
          }
        })
        .then(() =>
          axios.post(
            `${endpoint}?fileName=${encodeURIComponent(
              fileName
            )}&contentType=${contentType}`,
            arquivoUrl
          )
        )
        .then(
          ({ data }) =>
            (file.arquivoDocumento = { ...data, documento: file.documento?.id })
        )
  );
};

export const salvarArquivos = (processoId, files, isFileCloud) => {
  const promises = files
    .map(file => {
      const formData = new FormData();

      formData.append('files', file);

      if (file.documento) {
        formData.append('documento', file.documento.id);
      }

      return formData;
    })
    .map(data => {
      let url = '/arquivo';

      if (data.get('documento')) {
        url = `${url}/documento/${data.get('documento')}`;
      }

      const fileName = data.get('files').nome;

      if (!isFileCloud) {
        return axios.post(
          `${resource}${url}/${processoId}`,
          data,
          useTransProgress(fileName, TransProgressKind.UPLOAD)
        );
      }

      url = `${resource}/cloud${url}${processoId}`;

      const configs = useTransProgress(fileName, TransProgressKind.UPLOAD);

      const contentType = getContentType(fileName);

      return ArquivoService.getUrlUpload(fileName, contentType).then(
        ({ data: arquivoUrl }) =>
          defaultAxios
            .put(arquivoUrl.urlAssinada, data.get('files'), {
              ...transientRequest(configs, true, false),
              headers: {
                'Content-Type': contentType
              }
            })
            .then(res => {
              const fileNameEncode = encodeURIComponent(fileName);
              return axios.post(
                `${url}&fileName=${fileNameEncode}&contentType=${contentType}`,
                arquivoUrl,
                transientRequest(configs, false, true)
              );
            })
      );
    });

  return Promise.all(promises);
};

export const agruparArquivosEmUnicoPdf = (agrupamento, processoId) => {
  return axios.post(`${resource}/agrupar-arquivos${processoId}`, agrupamento);
};

export const salvarArquivoTemplate = (processoId, template) => {
  return axios.post(`${resource}/template${processoId}`, template);
};

export const cancelarArquivo = (identificador, motivo) => {
  return axios.put(`${resource}/cancelar-arquivo/${identificador}`, motivo);
};

export const salvarArquivoPdf = (
  processoUuid,
  templateHtml,
  fileName = 'modeloDocumentoProcesso'
) => {
  const exporterParam = {
    type: 'PDF',
    fileName
  };

  return axios.post(`${resource}/template-pdf/${processoUuid}`, {
    name: fileName,
    text: templateHtml,
    exporterParam
  });
};

export const atualizarArquivos = (id, newFiles) => {
  return axios.put(`${resource}/atualizar-arquivos/${id}`, newFiles);
};

export const findByIdentificador = identificador => {
  return axios.get(`${resource}/find-by-identificador/${identificador}`);
};

export const downloadAnexosProcesso = arquivos => {
  return axios
    .post(
      `${resource}/arquivos/zip/gerar-arquivos`,
      { arquivos },
      {
        responseType: 'blob'
      }
    )
    .then(response => {
      const headerContent = response.headers['content-disposition'];
      const searchName = headerContent.split('filename="')[1];
      const fileName = searchName.replace('"', '');
      return { arquivo: response.data, fileName };
    });
};
