import {
  ActionButton,
  ActionsGroup,
  Col,
  FormattedDate,
  FormikAutocomplete,
  Row,
  Table,
  TableChild
} from '@elotech/components';
import { InlineButton, TableContainer } from 'common/components';
import { useLoading } from 'common/hooks';
import { Form, Formik } from 'formik';
import { LABEL_PROCESSO } from 'labels';
import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { ProcessoReferenciadoService, ProcessoService } from 'service';

const ReferenciarChildTable = ({ processo, useTableChild = true }) => {
  const [loading, setLoading] = useLoading();
  const [referenciados, setReferenciados] = useState([]);

  useEffect(() => {
    if (processo) {
      setLoading(
        ProcessoReferenciadoService.getReferenciadosById(processo.id).then(
          ({ data }) => {
            setReferenciados(data);
          }
        )
      );
    }
  }, []);

  const renderForm = () => (
    <>
      <Formik
        initialValues={{ processo: undefined }}
        onSubmit={(values, actions) => {
          setLoading(
            ProcessoReferenciadoService.save(processo, values.processo).then(
              ({ data }) => {
                setReferenciados(prev => [...prev, data]);
                actions.resetForm();
              }
            )
          );
        }}
      >
        {formProps => (
          <Form>
            <Row>
              <FormikAutocomplete
                label={LABEL_PROCESSO}
                size={4}
                name="processo"
                onSearch={ProcessoService.findAllProcessoArquivadoAutocomplete}
                getOptionLabel={p =>
                  `${p.numero}/${p.ano} - ${p.tipo?.id} - ${p.tipo?.descricao}`
                }
              />
              <Col md={2} className="mt-xs">
                <FormGroup>
                  <InlineButton
                    label="Referenciar"
                    disabled={!formProps.values.processo}
                    onClick={formProps.submitForm}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <TableContainer>
        <Table
          loading={loading}
          values={referenciados}
          keyExtractor={r => r.id}
        >
          <Table.Column
            header={LABEL_PROCESSO}
            value={r => `${r.numero}/${r.ano}`}
          />
          <Table.Column
            header="Tipo"
            value={r => `${r.tipo?.id} - ${r.tipo?.descricao}`}
          />
          <Table.Column
            header="Data Processo"
            value={r => <FormattedDate value={r.dataProcesso} />}
          />
          <Table.Column header="Requerente" value={r => r.pessoa?.nome} />
          <Table.Column
            header="Local Atual"
            value={r => `${r.localDestino?.id} - ${r.localDestino?.descricao}`}
          />
          <Table.Column
            header="Assunto"
            value={r => `${r.assunto?.id} - ${r.assunto?.descricao}`}
          />
          <Table.Column header="Parecer" value={r => r.parecer?.parecer} />
          <Table.Column
            header=""
            value={(r, index) => (
              <ActionsGroup>
                <ActionButton
                  icon="trash"
                  label="Remover"
                  onClick={() =>
                    setLoading(
                      ProcessoReferenciadoService.remove(r).then(() => {
                        setReferenciados(prev => [
                          ...prev.slice(0, index),
                          ...prev.slice(index + 1)
                        ]);
                      })
                    )
                  }
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      {useTableChild ? <TableChild>{renderForm()}</TableChild> : renderForm()}
    </>
  );
};

export default ReferenciarChildTable;
