import { axios } from 'libs';

const resource = '/usuarios-locais';

export const findAllByLocalAndEntidade = (local, search) => {
  return axios.get(`${resource}/autocomplete/${local.id}`, {
    params: {
      search
    }
  });
};

export const findAllByEntidade = search => {
  return axios.get(`${resource}/autocomplete`, {
    params: {
      search
    }
  });
};

export const findAllByLocalId = localId => {
  return axios.get(`${resource}/local/${localId}`);
};

export const deleteByUsuarioAndLocal = (usuarioId, localId) => {
  return axios.delete(`${resource}/usuario/${usuarioId}/local/${localId}`);
};

export const save = (usuarioId, usuarioLocal) => {
  return axios.post(`${resource}/usuario/${usuarioId}`, usuarioLocal);
};
