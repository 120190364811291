import {
  Col,
  FormikAutocomplete,
  FormikCheckBox,
  FormikTextArea,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { InlineButton, Label } from 'common/components';
import { Formik } from 'formik';
import { LABEL_PARECER } from 'labels';
import { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import {
  EntidadeService,
  LocalService,
  SituacaoService,
  UsuarioAusenciaService,
  UsuarioLocalService
} from 'service';

import { TramiteArquivo } from './TramiteArquivo';
import { TramitesProcessosPareceres } from './TramitesProcessosPareceres';
import { getLocalByRoteiro } from './TramiteUtils';

const validationSchema = Yup.object().shape({
  local: Yup.object().required('Local Destino é obrigatório'),
  situacao: Yup.object().required('Situação é obrigatória'),
  usuarioEncaminhamento: Yup.object().test(
    'usuarioAusente',
    'Usuário registrou ausência hoje',
    async usuario => {
      if (!usuario?.loginUsuario) {
        return true;
      }
      const ausencias = (
        await UsuarioAusenciaService.findAusencias(usuario?.loginUsuario)
      )?.data;

      return !ausencias?.length;
    }
  ),
  entidadeDestino: Yup.object().test(
    'entidadeDestinoRequired',
    'Entidade é obrigatória',
    function (value) {
      return !this.parent.processo?.tramitaEntreEntidades || !!value;
    }
  )
});

const acoes = {
  RECEBER_ENCAMINHAR: 'RECEBER_ENCAMINHAR',
  REABRIR_ENCAMINHAR: 'REABRIR_ENCAMINHAR'
};

export const TramiteEncaminharForm = ({
  processo,
  situacao,
  onTramitar,
  hasProcessoPai,
  processos = [],
  acao = '',
  attProcessos,
  paramProcessoMp,
  ocultaProcFis = false,
  ocultaArquivo = false
}) => {
  const formikRef = useRef(null);

  const receberEncaminhar = acao === acoes.RECEBER_ENCAMINHAR;
  const reabrirEncaminhar = acao === acoes.REABRIR_ENCAMINHAR;
  const [exibirComponente, setExibirComponente] = useState(false);

  useEffect(() => {
    if (!formikRef.current.state.values.situcao) {
      formikRef.current?.setFieldValue('situacao', situacao);
    }
  }, [situacao]);

  useEffect(() => {
    if (processos?.length > 0) {
      setExibirComponente(true);
    }
    if (processo) {
      if (!formikRef.current?.state?.values?.local) {
        formikRef.current?.setFieldValue(
          'local',
          getLocalByRoteiro(processo, 'encaminhar')
        );
      }
      if (!processo.tramitaEntreEntidades) {
        setExibirComponente(true);
      }
    }
  }, [processo, processos]);

  const onSearchLocal = search => {
    if (processo?.tramitaEntreEntidades) {
      return LocalService.findByEntidadeSearch(
        formikRef.current.state.values.entidadeDestino.id,
        search
      );
    }
    return LocalService.findAllLocaisAutocomplete(search);
  };

  const getBtnLabel = () => {
    if (receberEncaminhar) {
      return 'Receber e Encaminhar';
    }

    if (reabrirEncaminhar) {
      return 'Reabrir e Encaminhar';
    }

    return 'Encaminhar';
  };

  const getLabelCopiarParecer = () => {
    const { values } = formikRef.current?.state || { values: {} };

    if (values.parecerAcao && !values.parecer) {
      return 'Recebimento para Encaminhamento';
    } else {
      return 'Encaminhamento para Recebimento';
    }
  };

  const copiarParecer = () => {
    const { values } = formikRef.current?.state || { values: {} };

    if (values.parecerAcao && !values.parecer) {
      formikRef.current?.setFieldValue('parecer', values.parecerAcao);
    } else if (values.parecer && !values.parecerAcao) {
      formikRef.current?.setFieldValue('parecerAcao', values.parecer);
    }
  };

  const isCopiarParecer = () => {
    const { values } = formikRef.current?.state || { values: {} };

    return (
      (!values.parecer && values.parecerAcao) ||
      (values.parecer && !values.parecerAcao)
    );
  };

  return (
    <Formik
      ref={formikRef}
      onSubmit={onTramitar}
      enableReinitialize
      initialValues={{
        situacao,
        parecer: '',
        arquivos: [],
        entidadeDestino: undefined,
        local: undefined,
        notaPrivada: false,
        parecerAcao: undefined,
        usuarioEncaminhamento: undefined,
        processoFisico: false,
        processo,
        processos
      }}
      validationSchema={validationSchema}
    >
      {formProps => {
        return (
          <>
            <Row>
              <FormikAutocomplete
                size={paramProcessoMp ? 9 : 6}
                name="situacao"
                label="Situação"
                onSearch={SituacaoService.findAllAutocomplete}
                getOptionLabel={s => `${s.id} - ${s.descricao}`}
              />
              {!paramProcessoMp && (
                <FormikCheckBox
                  size={3}
                  className="mt-xs"
                  name="notaPrivada"
                  label="Nota Privada"
                />
              )}
              {processos?.length === 0 && !ocultaProcFis && (
                <FormikCheckBox
                  size={3}
                  className="mt-xs"
                  name="processo.processoFisico"
                  label="Processo Físico"
                />
              )}
            </Row>
            <Row>
              {processo?.tramitaEntreEntidades && (
                <FormikAutocomplete
                  size={6}
                  name="entidadeDestino"
                  label="Entidade"
                  getOptionLabel={e => `${e.id} - ${e.nome}`}
                  onSearch={EntidadeService.findAllAutoComplete}
                  onItemSelected={(_, value) => {
                    if (!value) {
                      formProps.setFieldValue('entidadeDestino', undefined);
                      formProps.setFieldValue('local', undefined);
                      formProps.setFieldValue(
                        'usuarioEncaminhamento',
                        undefined
                      );
                      setExibirComponente(false);
                    } else {
                      setExibirComponente(true);
                    }
                  }}
                />
              )}
              {exibirComponente && (
                <>
                  <FormikAutocomplete
                    size={6}
                    name="local"
                    label="Local Destino"
                    disabled={processo?.assunto?.controlaTramitacao}
                    getOptionLabel={l => `${l.id} - ${l.descricao}`}
                    onItemSelected={(form, value) => {
                      if (!value) {
                        form.setFieldValue('local', undefined);
                        form.setFieldValue('usuarioEncaminhamento', undefined);
                      }
                    }}
                    onSearch={search => onSearchLocal(search)}
                  />
                  <FormikAutocomplete
                    size={6}
                    fast={false}
                    name="usuarioEncaminhamento"
                    label="Usuário Encaminhamento"
                    disabled={
                      processo?.assunto?.controlaTramitacao ||
                      !formProps.values.local
                    }
                    getOptionLabel={u =>
                      `${u.nomeUsuario} - ${u.loginUsuarioMask}`
                    }
                    onSearch={search =>
                      UsuarioLocalService.findAllByLocalAndEntidade(
                        formProps.values.local,
                        search
                      )
                    }
                  />
                </>
              )}
            </Row>
            {!ocultaArquivo && (
              <Row>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label>Arquivos</Label>
                    <TramiteArquivo formProps={formProps} />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <SectionTitle>{LABEL_PARECER}</SectionTitle>
            {receberEncaminhar && (
              <Row>
                <FormikTextArea
                  size={12}
                  name="parecerAcao"
                  label={`${LABEL_PARECER} Recebimento`}
                />
              </Row>
            )}
            <Row>
              <FormikTextArea
                size={12}
                name="parecer"
                label={
                  LABEL_PARECER + (receberEncaminhar ? ' Encaminhamento' : '')
                }
              />
            </Row>
            {processos?.length > 0 && (
              <TramitesProcessosPareceres
                processos={processos}
                attProcessos={attProcessos}
                paramProcessoMp={paramProcessoMp}
                ocultaProcFis={ocultaProcFis}
              />
            )}
            <Row className="mt-xs">
              <Col md={12} className="right ml-xs">
                {isCopiarParecer() && receberEncaminhar && (
                  <InlineButton
                    label={`Copiar Parecer do ${getLabelCopiarParecer()}`}
                    onClick={copiarParecer}
                  />
                )}
                <InlineButton
                  disabled={hasProcessoPai}
                  label={getBtnLabel()}
                  onClick={formProps.submitForm}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};
