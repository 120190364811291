import { Col, Loading, Row } from '@elotech/components';
import { InlineButton, alert } from 'common/components';
import { useLoading } from 'common/hooks';
import { usePermissions } from 'hooks';
import { LABEL_DESFAVORITAR, LABEL_FAVORITAR } from 'labels';
import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { keycloakRoles, seletores } from 'roles';
import {
  ModeloImpressaoService,
  ProcessoFavoritoService,
  ProcessoRelatorioService,
  ProcessoService
} from 'service';
import {
  getEntidadeSelector,
  getIsLoggedSelector,
  getUsernameSelector
} from 'state';

import { tipoModeloImpressaoFormat } from '../modelosImpressao/TipoEnum';
import AnexosChildTable from '../vincular-processos/AnexosChildTable';
import ReferenciarChildTable from '../vincular-processos/ReferenciarChildTable';
import {
  ProcessosStateTypes,
  getLinksVisibility,
  processosStateReducer
} from './ProcessoUtils';
import { TramiteFormModal } from './tramites/TramiteFormModal';

const initialState = {
  agrupados: [],
  fastTramite: '',
  usuario: {
    alteraTodosProc: false,
    usuarioLocal: []
  },
  showTimeline: false,
  quickProcesso: undefined,
  bloqueioVisualizacao: false,
  favoritos: []
};

export const ProcessoAcoes = ({
  processo,
  reloadProcesso,
  onAgruparArquivos,
  onImprimirPapeleta,
  onFecharReabrirProcesso,
  onVoltar,
  hideButtons = [],
  onImprimirTodosOsAnexos,
  showTramitaEntidades = true,
  onGeraProcessoReferenciado
}) => {
  const history = useHistory();
  const [loading, setLoading] = useLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const { isLogged, usuario } = useSelector(state => ({
    usuario: getUsernameSelector(state),
    isLogged: getIsLoggedSelector(state)
  }));
  const { entidadeLogada } = useSelector(state => ({
    entidadeLogada: getEntidadeSelector(state)
  }));
  const { hasPermission } = usePermissions();
  const [state, updateState] = useReducer(processosStateReducer, initialState);
  const processoIdWithEntidade = `${processo?.id}&entidade=${processo?.entidade?.id}`;
  const [showAnexar, setShowAnexar] = useState(false);
  const [showReferenciar, setShowReferenciar] = useState(false);

  useEffect(() => {
    if (processo?.id && isLogged) {
      setLoading(getFavorite());
    }
  }, [processo]);

  const hasPermissionReceber = hasPermission(
    keycloakRoles.receberProcesso,
    seletores.receberProcesso
  );

  const hasPermissionEncaminhar = hasPermission(
    keycloakRoles.encaminharProcesso,
    seletores.encaminharProcesso
  );
  const hasPermissionReabrir = hasPermission(
    keycloakRoles.reabrirProcesso,
    seletores.reabrirProcesso
  );
  const hasPermissionArquivar = hasPermission(
    keycloakRoles.arquivarProcesso,
    seletores.arquivarProcesso
  );

  const visible = getLinksVisibility(processo);

  const getFavorite = () => {
    return ProcessoFavoritoService.existsByProcessoAndUsuario(
      processo,
      usuario
    ).then(({ data }) => setIsLiked(data));
  };

  const handleFormModal = (processo, quickAction) => {
    updateState({
      type: ProcessosStateTypes.HANDLE_FORM_MODAL,
      payload: { quickProcesso: processo, fastTramite: quickAction }
    });

    if (quickAction === '') {
      setLoading(reloadProcesso());
    }
  };

  const handleLike = () => {
    const likeHandler = isLiked
      ? ProcessoFavoritoService.remove
      : ProcessoFavoritoService.save;

    return likeHandler(processo, usuario).then(() => getFavorite());
  };

  const handleAlterarTramiteEntreEntidades = () => {
    return ProcessoService.alterarTramiteEntreEntidades(processo?.id).then(() =>
      onVoltar()
    );
  };

  const isPermiteAlterarTramiteEntreEntidade = () => {
    return processo?.entidade?.id === entidadeLogada?.id;
  };

  const getReportByModelo = (serviceMethod, tipo) => {
    const wrappedServiceMethod = modelo =>
      serviceMethod(processo, modelo || '');

    return ModeloImpressaoService.searchCustomRpt(tipo).then(({ data }) => {
      if (data.length === 0) {
        return wrappedServiceMethod();
      } else if (data.length === 1) {
        return wrappedServiceMethod(data[0].modelo);
      }

      const options = data
        .map(item => ({ [item.modelo]: item.descricao }))
        .reduce((acc, current) => ({ ...acc, ...current }), {});

      alert({
        title: 'Selecione um relatório',
        input: 'select',
        type: 'question',
        inputOptions: options,
        inputPlaceholder: 'Selecione um relatório',
        showCancelButton: true,
        confirmButtonText: 'Emitir',
        cancelButtonText: 'Cancelar',
        onConfirm: async value => await wrappedServiceMethod(value),
        inputValidator: value => {
          return new Promise(resolve => {
            if (value) {
              resolve();
            } else {
              resolve('Selecione um modelo de relatório.');
            }
          });
        }
      });
    });
  };

  const buttons = [
    {
      label: 'Imprimir',
      icon: 'fa fa-print',
      show: isLogged,
      onClick: () => window.print()
    },
    {
      label: 'Agrupar Arquivos',
      icon: 'fa fa-copy',
      show: isLogged,
      onClick: () => onAgruparArquivos()
    },
    {
      label: 'Capa',
      icon: 'fa fa-bookmark',
      show: true,
      onClick: () =>
        setLoading(
          ProcessoRelatorioService.createCapaProcesso(processoIdWithEntidade)
        )
    },
    {
      label: 'Comprovante',
      icon: 'fa fa-check-square',
      show: true,
      onClick: () =>
        setLoading(
          ProcessoRelatorioService.createComprovanteProcesso(
            processoIdWithEntidade
          )
        )
    },
    {
      label: 'Requerimento',
      icon: 'fa fa-clipboard-list',
      show: isLogged,
      onClick: () =>
        setLoading(
          getReportByModelo(
            ProcessoRelatorioService.createRequerimentoProcesso,
            tipoModeloImpressaoFormat.REQUERIMENTO
          )
        )
    },
    {
      label: 'Papeleta',
      icon: 'fa fa-paper-plane',
      show: isLogged,
      onClick: () => onImprimirPapeleta()
    },
    {
      label: 'Etiqueta',
      icon: 'fa fa-ticket-alt',
      show: isLogged,
      onClick: () =>
        setLoading(
          getReportByModelo(
            ProcessoRelatorioService.createEtiquetaProcesso,
            tipoModeloImpressaoFormat.ETIQUETA
          )
        )
    },
    {
      label: processo.fechado ? 'Desfazer Fechamento' : 'Fechamento Completo',
      icon: processo.fechado ? 'fa fa-unlock' : 'fa fa-lock',
      show: isLogged && processo?.funcao === 'ARQUIVADO',
      onClick: () => onFecharReabrirProcesso()
    },
    {
      label: 'Visualizar',
      icon: 'fa fa-eye',
      show: true,
      onClick: () =>
        setLoading(
          ProcessoRelatorioService.createVisualizacaoProcesso(
            processo?.id,
            processo?.entidade?.id
          )
        )
    },
    {
      label: 'Avaliar',
      icon: 'fa fa-star',
      show:
        isLogged &&
        !processo?.processoAvaliado &&
        (processo?.externo || processo?.ouvidoria),
      onClick: () =>
        setLoading(
          ProcessoService.avaliarProcesso(processo).then(() =>
            history.push('/processos-avaliacao')
          )
        )
    },
    {
      label: isLiked ? LABEL_DESFAVORITAR : LABEL_FAVORITAR,
      icon: isLiked ? 'fa fa-heart-broken' : 'fa fa-heart',
      show: isLogged,
      onClick: () => setLoading(handleLike())
    },
    {
      label: 'Receber',
      icon: 'fa fa-file-download',
      show: isLogged && visible.RECEBER && hasPermissionReceber,
      onClick: () => handleFormModal(processo, 'RECEBER')
    },
    {
      label: 'Receber e Encaminhar',
      icon: 'fa fa-magic',
      show:
        isLogged &&
        visible.RECEBER_ENCAMINHAR &&
        hasPermissionReceber &&
        hasPermissionEncaminhar,
      onClick: () => handleFormModal(processo, 'RECEBER_ENCAMINHAR')
    },
    {
      label: 'Encaminhar',
      icon: 'fa fa-file-export',
      show: isLogged && visible.ENCAMINHAR && hasPermissionEncaminhar,
      onClick: () => handleFormModal(processo, 'ENCAMINHAR')
    },
    {
      label: 'Arquivar',
      icon: 'fa fa-lock',
      show: isLogged && visible.ARQUIVAR && hasPermissionArquivar,
      onClick: () => handleFormModal(processo, 'ARQUIVAR')
    },
    {
      label: 'Reabrir',
      icon: 'fa fa-star',
      show: isLogged && visible.REABRIR && hasPermissionReabrir,
      onClick: () => handleFormModal(processo, 'REABRIR')
    },
    {
      label: processo.tramitaEntreEntidades
        ? 'Não Tramitar Entre Entidades'
        : 'Tramitar Entre Entidades',
      icon: processo.tramitaEntreEntidades ? 'fa fa-times' : 'fa fa-check',
      show:
        isLogged &&
        showTramitaEntidades &&
        isPermiteAlterarTramiteEntreEntidade(),
      onClick: () => setLoading(handleAlterarTramiteEntreEntidades(processo))
    },
    {
      label: 'Download Completo dos Anexos',
      icon: 'fa fa-print',
      show: isLogged && processo?.arquivos?.length > 1,
      onClick: () => onImprimirTodosOsAnexos()
    },
    {
      label: 'Gerar Processo Referenciado',
      icon: 'fa fa-copy',
      show: isLogged,
      onClick: () => onGeraProcessoReferenciado(processo)
    },
    {
      label: 'Anexar Processos',
      icon: 'fa fa-paperclip',
      show: isLogged,
      onClick: () => setShowAnexar(prev => !prev)
    },
    {
      label: 'Referenciar Processos',
      icon: 'fa fa-random',
      show: isLogged,
      onClick: () => setShowReferenciar(prev => !prev)
    }
  ].filter(
    button =>
      !hideButtons.some(
        hideButton => button.label.toUpperCase() === hideButton.toUpperCase()
      )
  );

  return (
    <div className="mb-xs no-print">
      <Loading loading={loading} />
      <Row>
        <Col sm={6}>
          <InlineButton
            icon="fa fa-chevron-left"
            onClick={onVoltar}
            label="Voltar"
          />
        </Col>
        <Col sm={6}>
          <InlineButton
            icon="fa fa-ellipsis-v"
            className="pull-right mr-none"
            onClick={() => setIsOpen(prev => !prev)}
            label={`${isOpen ? 'Esconder' : 'Mostrar'} Ações`}
          />
        </Col>
      </Row>
      {isOpen && (
        <Row>
          {buttons
            .filter(buttonProps => buttonProps.show)
            .map(buttonProps => (
              <Col md={2} className="mt-xs" key={buttonProps.label}>
                <InlineButton {...buttonProps} style={{ minWidth: '100%' }} />
              </Col>
            ))}
        </Row>
      )}
      {showAnexar && (
        <AnexosChildTable processo={processo} useTableChild={false} />
      )}
      {showReferenciar && (
        <ReferenciarChildTable processo={processo} useTableChild={false} />
      )}
      {state.quickProcesso && state.fastTramite && (
        <TramiteFormModal
          tipo={state.fastTramite}
          processo={state.quickProcesso}
          onClose={() => handleFormModal(undefined, '')}
        />
      )}
    </div>
  );
};
