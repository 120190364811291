import { NotFoundPage } from '@elotech/components';
import {
  LABEL_ASSUNTO,
  LABEL_ASSUNTOS,
  LABEL_PROCESSO,
  LABEL_PROCESSOS
} from 'labels';
import ProcessoMPPage from 'pages/processoMP/ProcessoMPPage';
import { lazy } from 'react';
import { Route, Switch } from 'react-router';
import { keycloakRoles, seletores } from 'roles';

import NotificacaoPageList from '../pages/notificacao/NotificacaoPageList';
import ProcessoMpViewPage from '../pages/processoMP/ProcessoMpViewPage';
import { ProtocoloProtectedRoute } from './ProtocoloProtectedRoute';

const AberturaProcessoExterno = lazy(() =>
  import('../pages/aberturaProcessoExterno/AberturaProcessoExterno')
);
const AssinanteList = lazy(() => import('../pages/assinante/AssinanteList'));
const AssinanteForm = lazy(() => import('../pages/assinante/AssinanteForm'));
const AssinaturaConfigForm = lazy(() =>
  import('../pages/processos-novo/assinatura/AssinaturaConfigForm')
);
const AssinaturaList = lazy(() =>
  import('../pages/processos-novo/assinatura/AssinaturaList')
);
const ConsultaProcessos = lazy(() =>
  import('../pages/consultaProcesso/ConsultaProcessos')
);
const ConsultaProcessoChave = lazy(() =>
  import('../pages/consulta-processo-chave/ConsultaProcessoChave')
);
const AssuntoForm = lazy(() => import('../pages/assuntos/AssuntoForm'));
const AssuntosList = lazy(() => import('../pages/assuntos/AssuntosList'));
const AssuntoHistory = lazy(() => import('../pages/assuntos/AssuntoHistory'));
const AtualizacaoPessoa = lazy(() =>
  import('../pages/pessoa/AtualizacaoPessoa')
);
const AvaliacaoProcessosList = lazy(() =>
  import('../pages/avaliacaoProcesso/AvaliacaoProcessosList')
);
const BairroForm = lazy(() => import('../pages/bairros/BairroForm'));
const BairroList = lazy(() => import('../pages/bairros/BairroList'));
const CaixaForm = lazy(() => import('../pages/caixas/CaixaForm'));
const CaixaList = lazy(() => import('../pages/caixas/CaixaList'));
const CidadeForm = lazy(() => import('../pages/cidades/CidadeForm'));
const CidadeList = lazy(() => import('../pages/cidades/CidadeList'));
const ComprovanteEntregaProcessoForm = lazy(() =>
  import('../pages/comprovanteEntregaProcesso/ComprovanteEntregaProcessoForm')
);
const ComprovanteEntregaProcessoList = lazy(() =>
  import('../pages/comprovanteEntregaProcesso/ComprovanteEntregaProcessoList')
);
const ConsultaAutenticidadeForm = lazy(() =>
  import('../pages/processos-novo/assinatura/ConsultaAutenticidadeForm')
);
const CoordenadoriaForm = lazy(() =>
  import('../pages/coordenadorias/CoordenadoriaForm')
);
const CoordenadoriaList = lazy(() =>
  import('../pages/coordenadorias/CoordenadoriaList')
);
const CorrecaoFluxo = lazy(() =>
  import('../pages/correcaoFluxo/CorrecaoFluxo')
);
const DashboardBuilder = lazy(() =>
  import('../pages/dashboards/DashboardBuilder')
);
const DashboardList = lazy(() => import('../pages/dashboards/DashboardList'));
const DiretoriaForm = lazy(() => import('../pages/diretorias/DiretoriaForm'));
const DiretoriaList = lazy(() => import('../pages/diretorias/DiretoriaList'));
const DocumentoForm = lazy(() => import('../pages/documentos/DocumentoForm'));
const DocumentoList = lazy(() => import('../pages/documentos/DocumentoList'));
const TipoAndamentoForm = lazy(() =>
  import('../pages/tipo-andamento/TipoAndamentoForm')
);
const TipoAndamentoList = lazy(() =>
  import('../pages/tipo-andamento/TipoAndamentoList')
);
const FluxoAssinaturaList = lazy(() =>
  import('../pages/fluxo-assinatura/FluxoAssinaturaList')
);
const FluxoAssinaturaForm = lazy(() =>
  import('../pages/fluxo-assinatura/FluxoAssinaturaForm')
);
const EnviarProcessosTributos = lazy(() =>
  import('../pages/enviar-processos-fila/EnviarProcessosTributos')
);
const EstatisticasProcesso = lazy(() =>
  import('../pages/relatorios/EstatisticasProcesso')
);
const GerenciaForm = lazy(() => import('../pages/gerencias/GerenciaForm'));
const GerenciaList = lazy(() => import('../pages/gerencias/GerenciaList'));
const GrauSigiloForm = lazy(() =>
  import('../pages/grauSigilos/GrauSigiloForm')
);
const GrauSigiloList = lazy(() =>
  import('../pages/grauSigilos/GrauSigiloList')
);
const GrupoAssinanteList = lazy(() =>
  import('../pages/grupo-assinante/GrupoAssinanteList')
);
const GrupoAssinanteForm = lazy(() =>
  import('../pages/grupo-assinante/GrupoAssinanteForm')
);
const Home = lazy(() => import('../pages/home/Home'));
const LocalForm = lazy(() => import('../pages/locais/LocalForm'));
const LocalList = lazy(() => import('../pages/locais/LocalList'));
const LoginPage = lazy(() => import('../pages/login/LoginPage'));
const LogradouroForm = lazy(() =>
  import('../pages/logradouros/LogradouroForm')
);
const LogradouroList = lazy(() =>
  import('../pages/logradouros/LogradouroList')
);
const ModelosDocumentosList = lazy(() =>
  import('../pages/modelosDocumentos/modelos/ModelosDocumentosList')
);
const ModelosDocumentosForm = lazy(() =>
  import('../pages/modelosDocumentos/modelos/ModelosDocumentosForm')
);
const ModeloImpressaoForm = lazy(() =>
  import('../pages/modelosImpressao/ModeloImpressaoForm')
);
const ModeloImpressaoList = lazy(() =>
  import('../pages/modelosImpressao/ModeloImpressaoList')
);
const ParametroList = lazy(() => import('../pages/parametros/ParametroList'));
const ParametrizacaoIntegracaoList = lazy(() =>
  import('../pages/parametrizacaoIntegracao/ParametrizacaoIntegracaoList')
);
const ParametrizacaoIntegracaoForm = lazy(() =>
  import('../pages/parametrizacaoIntegracao/ParametrizacaoIntegracaoForm')
);
const PessoaForm = lazy(() => import('../pages/pessoa/PessoaForm'));
const PessoasList = lazy(() => import('../pages/pessoa/PessoasList'));
const PessoaHistory = lazy(() => import('../pages/pessoa/PessoaHistory'));
const PessoaView = lazy(() => import('../pages/pessoa/PessoaView'));
const ProcessoNovoForm = lazy(() =>
  import('../pages/processos-novo/ProcessoNovoForm')
);
const ProcessosNovoList = lazy(() =>
  import('../pages/processos-novo/ProcessosNovoList')
);
const ProcessoNovoHistory = lazy(() =>
  import('../pages/processos-novo/ProcessoNovoHistory')
);
const ProcessoNovoView = lazy(() =>
  import('../pages/processos-novo/ProcessoNovoView')
);
const ProcessoDocumentosView = lazy(() =>
  import('../pages/processos-novo/ProcessoDocumentosView')
);
const ProcessosAgrupados = lazy(() =>
  import('../pages/processos-agrupados/ProcessosAgrupados')
);
const ProcessosLocais = lazy(() =>
  import('../pages/indicadores/ProcessosLocais')
);
const RelatorioComprovanteEncaminhamento = lazy(() =>
  import('../pages/relatorios/ComprovanteEncaminhamento')
);
const RelatorioEtiqueta = lazy(() => import('../pages/relatorios/Etiqueta'));
const RelatorioPocessoGeral = lazy(() =>
  import('../pages/relatorios/Processo')
);
const RelatorioTramite = lazy(() => import('../pages/relatorios/Tramite'));
const RelatorioTramitePorLocal = lazy(() =>
  import('../pages/relatorios/TramitePorLocal')
);
const RelatorioReceberEncaminhar = lazy(() =>
  import('../pages/relatorios/ProcessoReceberEncaminhar')
);
const RoteiroForm = lazy(() => import('../pages/roteiros/RoteiroForm'));
const RoteiroList = lazy(() => import('../pages/roteiros/RoteiroList'));
const SecretariaForm = lazy(() =>
  import('../pages/secretarias/SecretariaForm')
);
const SecretariaList = lazy(() =>
  import('../pages/secretarias/SecretariaList')
);
const SituacaoForm = lazy(() => import('../pages/situacoes/SituacaoForm'));
const SituacaoList = lazy(() => import('../pages/situacoes/SituacaoList'));
const TipoNotificacaoForm = lazy(() =>
  import('../pages/tiposnotificacao/TipoNotificacaoForm')
);
const TipoNotificacaoList = lazy(() =>
  import('../pages/tiposnotificacao/TipoNotificacaoList')
);
const TipoParticipanteForm = lazy(() =>
  import('../pages/tiposparticipante/TipoParticipanteForm')
);
const TipoParticipanteList = lazy(() =>
  import('../pages/tiposparticipante/TipoParticipanteList')
);
const TipoProcessoForm = lazy(() =>
  import('../pages/tiposprocesso/TipoProcessoForm')
);
const TipoProcessoList = lazy(() =>
  import('../pages/tiposprocesso/TipoProcessoList')
);
const TipoProcessoHistory = lazy(() =>
  import('../pages/tiposprocesso/TipoProcessoHistory')
);
const UnicoCadastrosProtocolo = lazy(() =>
  import('../pages/iframe-components/UnicoCadastrosProtocolo')
);
const UnicoConsultaDinamica = lazy(() =>
  import('../pages/iframe-components/UnicoConsultaDinamica')
);
const UnicoRelatorioDinamico = lazy(() =>
  import('../pages/iframe-components/UnicoRelatorioDinamico')
);
const UnidadeForm = lazy(() => import('../pages/unidades/UnidadeForm'));
const UnidadeList = lazy(() => import('../pages/unidades/UnidadeList'));
const UnidadeOrcamentariaForm = lazy(() =>
  import('../pages/unidadesOrcamentaria/UnidadeOrcamentariaForm')
);
const UnidadeOrcamentariaList = lazy(() =>
  import('../pages/unidadesOrcamentaria/UnidadeOrcamentariaList')
);
const UsuarioForm = lazy(() => import('../pages/usuarios/UsuarioForm'));
const UsuarioList = lazy(() => import('../pages/usuarios/UsuarioList'));
const VariaveisList = lazy(() =>
  import('../pages/modelosDocumentos/variaveis/VariaveisList')
);
const VariaveisForm = lazy(() =>
  import('../pages/modelosDocumentos/variaveis/VariaveisForm')
);
const VincularProcessos = lazy(() =>
  import('../pages/vincular-processos/VincularProcessos')
);

const ControleAndamentosList = lazy(() =>
  import('../pages/controleDeAndamentos/ControleAndamentosList')
);
const DataIgnoradaForm = lazy(() =>
  import('../pages/datasignoradas/DataIgnoradaForm')
);
const DataIgnoradaList = lazy(() =>
  import('../pages/datasignoradas/DataIgnoradaList')
);

const ControleEmailList = lazy(() =>
  import('../pages/relatorios/ControleEmailList')
);

export const publicRoutes = [
  {
    path: ['/notificacoes'],
    component: NotificacaoPageList,
    title: 'Notificações',
    icon: 'envelope'
  },
  {
    path: ['/aberturaProcessoExterno', '/:entidade/aberturaProcessoExterno'],
    component: AberturaProcessoExterno,
    title: 'Abertura de Processo',
    icon: 'file'
  },
  {
    path: ['/consulta-autenticidade', '/:entidade/consulta-autenticidade'],
    component: ConsultaAutenticidadeForm,
    title: 'Consulta Autenticidade',
    icon: 'download'
  },
  {
    path: ['/consultaProcesso', '/:entidade/consultaProcesso'],
    component: ConsultaProcessos,
    title: 'Consulta Processos',
    icon: 'search'
  },
  {
    path: ['/consulta-processo-chave', '/:entidade/consulta-processo-chave'],
    component: ConsultaProcessoChave,
    title: 'Consulta Processo Por Chave',
    icon: 'key'
  },
  {
    path: ['/ouvidoria', '/:entidade/ouvidoria'],
    component: AberturaProcessoExterno,
    title: 'Ouvidoria',
    icon: 'file'
  },
  {
    path: '/login',
    component: LoginPage,
    title: 'Login',
    icon: 'user'
  }
];

export const protectedRoutes = [
  // dashboards e indicadores
  {
    path: '/',
    component: Home,
    title: 'Início',
    icon: 'home',
    role: keycloakRoles.home
  },
  {
    path: '/dashboards',
    component: DashboardList,
    title: 'Dashboards',
    icon: 'cube',
    role: keycloakRoles.cubeJsRead,
    seletor: seletores.cubejsDashboards,
    exact: true
  },
  {
    path: '/dashboards/:id',
    component: DashboardBuilder,
    title: 'Dashboards',
    icon: 'cube',
    parent: '/dashboards',
    role: keycloakRoles.cubeJsUpdate,
    seletor: seletores.cubejsDashboards,
    exact: true
  },
  {
    path: '/indicador-processos-locais',
    component: ProcessosLocais,
    title: 'Processos x Locais',
    icon: 'file-upload',
    role: keycloakRoles.indicadorProcessosLocais,
    seletor: seletores.indicadorProcessosLocais
  },
  // cadastros
  {
    path: '/assuntos',
    component: AssuntosList,
    title: LABEL_ASSUNTOS,
    icon: 'fa-align-left',
    role: keycloakRoles.assunto,
    seletor: seletores.assunto
  },
  {
    path: '/assunto/:id',
    component: AssuntoForm,
    title: LABEL_ASSUNTO,
    icon: 'fa-align-left',
    role: keycloakRoles.assuntoUpdate,
    seletor: seletores.assunto,
    parent: '/assuntos',
    exact: true
  },
  {
    path: '/assunto/:id/history',
    component: AssuntoHistory,
    title: 'Consulta de Logs do Assunto',
    icon: 'fa-align-left',
    parent: '/assuntos',
    role: keycloakRoles.assunto,
    seletor: seletores.assunto
  },
  {
    path: '/atualizacao/pessoas',
    component: AtualizacaoPessoa,
    title: 'Atualização Pessoa',
    icon: 'users',
    role: keycloakRoles.atualizacaoPessoa,
    seletor: seletores.atualizacaoPessoa
  },
  {
    path: '/bairros',
    component: BairroList,
    title: 'Bairros',
    icon: 'fa-map-signs',
    role: keycloakRoles.bairro,
    seletor: seletores.bairro
  },
  {
    path: '/bairro/:id',
    component: BairroForm,
    title: 'Bairro',
    icon: 'fa-map-signs',
    parent: '/bairros',
    role: keycloakRoles.bairroUpdate,
    seletor: seletores.bairro,
    exact: true
  },
  {
    path: '/caixas',
    component: CaixaList,
    title: 'Caixas',
    icon: 'fa-archive',
    role: keycloakRoles.caixa,
    seletor: seletores.caixa
  },
  {
    path: '/caixa',
    component: CaixaForm,
    title: 'Caixa',
    icon: 'fa-archive',
    parent: '/caixas',
    role: keycloakRoles.caixaUpdate,
    seletor: seletores.caixa,
    exact: true
  },
  {
    path: '/cidades',
    component: CidadeList,
    title: 'Cidades',
    icon: 'fa-archway',
    role: keycloakRoles.cidade,
    seletor: seletores.cidade
  },
  {
    path: '/cidade/:id',
    component: CidadeForm,
    title: 'Cidade',
    icon: 'fa-archway',
    parent: '/cidades',
    role: keycloakRoles.cidadeUpdate,
    seletor: seletores.cidade,
    exact: true
  },
  {
    path: '/coordenadorias',
    component: CoordenadoriaList,
    title: 'Coordenadorias',
    icon: 'fa-address-card',
    role: keycloakRoles.coordenadoria,
    seletor: seletores.coordenadoria
  },
  {
    path: '/coordenadoria/:id',
    component: CoordenadoriaForm,
    title: 'Coordenadoria',
    icon: 'fa-address-card',
    parent: '/coordenadorias',
    role: keycloakRoles.coordenadoriaUpdate,
    seletor: seletores.coordenadoria,
    exact: true
  },
  {
    path: '/diretorias',
    component: DiretoriaList,
    title: 'Diretorias',
    icon: 'fa-building',
    role: keycloakRoles.diretoria,
    seletor: seletores.diretoria
  },
  {
    path: '/diretoria/:id',
    component: DiretoriaForm,
    title: 'Diretoria',
    icon: 'fa-building',
    parent: '/diretorias',
    role: keycloakRoles.diretoriaUpdate,
    seletor: seletores.diretoria,
    exact: true
  },
  {
    path: '/documentos',
    component: DocumentoList,
    title: 'Documentos',
    icon: 'fa-folder-open',
    role: keycloakRoles.documentos,
    seletor: seletores.documentos
  },
  {
    path: '/documento/:id',
    component: DocumentoForm,
    title: 'Documento',
    icon: 'fa-folder-open',
    parent: '/documentos',
    role: keycloakRoles.documentosUpdate,
    seletor: seletores.documentos,
    exact: true
  },
  {
    path: '/gerencias',
    component: GerenciaList,
    title: 'Gerênciaas',
    icon: 'fa-id-card',
    role: keycloakRoles.gerencia,
    seletor: seletores.gerencia
  },
  {
    path: '/gerencia/:id',
    component: GerenciaForm,
    title: 'Gerência',
    icon: 'fa-id-card',
    parent: '/gerencias',
    role: keycloakRoles.gerenciaUpdate,
    seletor: seletores.gerencia,
    exact: true
  },
  {
    path: '/grauSigilos',
    component: GrauSigiloList,
    title: 'Graus de Sigilo',
    icon: 'fa-shield-alt',
    role: keycloakRoles.grauSigilo,
    seletor: seletores.grauSigilo
  },
  {
    path: '/grauSigilo/:id',
    component: GrauSigiloForm,
    title: 'Grau de Sigilo',
    icon: 'fa-shield-alt',
    parent: '/grauSigilos',
    role: keycloakRoles.grauSigiloUpdate,
    seletor: seletores.grauSigilo,
    exact: true
  },
  {
    path: '/locais',
    component: LocalList,
    title: 'Locais',
    icon: 'fa-map-pin',
    role: keycloakRoles.local,
    seletor: seletores.local
  },
  {
    path: '/local/:id',
    component: LocalForm,
    title: 'Local',
    icon: 'fa-map-pin',
    parent: '/locais',
    role: keycloakRoles.localUpdate,
    seletor: seletores.local,
    exact: true
  },
  {
    path: '/logradouros',
    component: LogradouroList,
    title: 'Logradouros',
    icon: 'fa-road',
    role: keycloakRoles.logradouro,
    seletor: seletores.logradouro
  },
  {
    path: '/logradouro/:id',
    component: LogradouroForm,
    title: 'Logradouro',
    icon: 'fa-road',
    parent: '/logradouros',
    role: keycloakRoles.logradouroUpdate,
    seletor: seletores.logradouro,
    exact: true
  },
  {
    path: '/modelos-impressao',
    component: ModeloImpressaoList,
    title: 'Modelos de Impressão',
    icon: 'fa-file',
    role: keycloakRoles.modeloImpressao,
    seletor: seletores.modeloImpressao
  },
  {
    path: '/modelo-impressao/:id',
    component: ModeloImpressaoForm,
    title: 'Modelo de Impressão',
    icon: 'fa-file',
    parent: '/modelos-impressao',
    role: keycloakRoles.modeloImpressaoUpdate,
    seletor: seletores.modeloImpressao,
    exact: true
  },
  {
    path: '/pessoas',
    component: PessoasList,
    title: 'Pessoas',
    icon: 'fa-users',
    role: keycloakRoles.pessoa,
    seletor: seletores.pessoa
  },
  {
    path: '/pessoa/:id',
    component: PessoaForm,
    title: 'Pessoa',
    icon: 'fa-users',
    parent: '/pessoas',
    role: keycloakRoles.pessoaUpdate,
    seletor: seletores.pessoa,
    exact: true
  },
  {
    path: '/pessoa/:id/history',
    component: PessoaHistory,
    title: 'Consulta de Logs da Pessoa',
    icon: 'fa-users',
    parent: '/pessoas',
    role: keycloakRoles.pessoa,
    seletor: seletores.pessoa,
    exact: true
  },
  {
    path: '/pessoa/view/:id',
    component: PessoaView,
    title: 'Pessoa',
    icon: 'fa-users',
    parent: '/pessoas',
    role: keycloakRoles.pessoaUpdate,
    seletor: seletores.pessoa,
    exact: true
  },
  {
    path: '/roteiros',
    component: RoteiroList,
    title: 'Roteiros',
    icon: 'fa-directions',
    role: keycloakRoles.roteiro,
    seletor: seletores.roteiro
  },
  {
    path: '/roteiro/:id',
    component: RoteiroForm,
    title: 'Roteiro',
    icon: 'fa-directions',
    parent: '/roteiros',
    role: keycloakRoles.roteiroUpdate,
    seletor: seletores.roteiro,
    exact: true
  },
  {
    path: '/secretarias',
    component: SecretariaList,
    title: 'Secretarias',
    icon: 'fa-building',
    role: keycloakRoles.secretaria,
    seletor: seletores.secretaria
  },
  {
    path: '/secretaria/:id',
    component: SecretariaForm,
    title: 'Secretaria',
    icon: 'fa-building',
    parent: '/secretarias',
    role: keycloakRoles.secretariaUpdate,
    seletor: seletores.secretaria,
    exact: true
  },
  {
    path: '/situacoes',
    component: SituacaoList,
    title: 'Situações',
    icon: 'fa-info',
    role: keycloakRoles.situacao,
    seletor: seletores.situacao
  },
  {
    path: '/situacao/:id',
    component: SituacaoForm,
    title: 'Situação',
    icon: 'fa-info',
    parent: '/situacoes',
    role: keycloakRoles.situacaoUpdate,
    seletor: seletores.situacao,
    exact: true
  },
  {
    path: '/tiposnotificacao',
    component: TipoNotificacaoList,
    title: 'Tipos de Notificação',
    icon: 'fa-comment',
    role: keycloakRoles.tipoNotificacao,
    seletor: seletores.tipoNotificacao
  },
  {
    path: '/tiponotificacao/:id',
    component: TipoNotificacaoForm,
    title: 'Tipo de Notificação',
    icon: 'fa-comment',
    parent: '/tiposnotificacao',
    role: keycloakRoles.tipoNotificacaoUpdate,
    seletor: seletores.tipoNotificacao,
    exact: true
  },
  {
    path: '/tipos-participante',
    component: TipoParticipanteList,
    title: 'Tipos de Participante',
    icon: 'fa-user-edit',
    role: keycloakRoles.tipoParticipante,
    seletor: seletores.tipoParticipante
  },
  {
    path: '/tipo-participante/:id',
    component: TipoParticipanteForm,
    title: 'Tipo de Participante',
    icon: 'fa-user-edit',
    parent: '/tipos-participante',
    role: keycloakRoles.tipoParticipanteUpdate,
    seletor: seletores.tipoParticipante,
    exact: true
  },
  {
    path: '/tiposprocesso',
    component: TipoProcessoList,
    title: `Tipos de ${LABEL_PROCESSOS}`,
    icon: 'fa-list-ul',
    role: keycloakRoles.tipo,
    seletor: seletores.tipo
  },
  {
    path: '/tipoprocesso/:id',
    component: TipoProcessoForm,
    title: `Tipo de ${LABEL_PROCESSO}`,
    icon: 'fa-list-ul',
    parent: '/tiposprocesso',
    role: keycloakRoles.tipoUpdate,
    seletor: seletores.tipo,
    exact: true
  },
  {
    path: '/tipoprocesso/:id/history',
    component: TipoProcessoHistory,
    title: 'Consulta de Logs do Tipo de Processo',
    icon: 'fa-list-ul',
    parent: '/tiposprocesso',
    role: keycloakRoles.tipo,
    seletor: seletores.tipo,
    exact: true
  },
  {
    path: '/unidades',
    component: UnidadeList,
    title: 'Unidades',
    icon: 'fa-building',
    role: keycloakRoles.unidade,
    seletor: seletores.unidade
  },
  {
    path: '/unidade/:id',
    component: UnidadeForm,
    title: 'Unidade',
    icon: 'fa-building',
    parent: '/unidades',
    role: keycloakRoles.unidadeUpdate,
    seletor: seletores.unidade,
    exact: true
  },
  {
    path: '/unidadesOrcamentaria',
    component: UnidadeOrcamentariaList,
    title: 'Unidades Orçamentárias',
    icon: 'fa-building',
    role: keycloakRoles.unidadeOrcamentaria,
    seletor: seletores.unidadeOrcamentaria
  },
  {
    path: '/unidadeOrcamentaria',
    component: UnidadeOrcamentariaForm,
    title: 'Unidade Orçamentária',
    icon: 'fa-building',
    parent: '/unidadesOrcamentaria',
    role: keycloakRoles.unidadeOrcamentariaUpdate,
    seletor: seletores.unidadeOrcamentaria,
    exact: true
  },
  {
    path: '/usuarios',
    component: UsuarioList,
    title: 'Usuários',
    icon: 'fa-user',
    role: keycloakRoles.usuario,
    seletor: seletores.usuario
  },
  {
    path: '/usuario/:id',
    component: UsuarioForm,
    title: 'Usuário',
    icon: 'fa-user',
    parent: '/usuarios',
    role: keycloakRoles.usuarioUpdate,
    seletor: seletores.usuario,
    exact: true
  },
  {
    path: '/datasignoradas',
    component: DataIgnoradaList,
    title: 'Datas Ignoradas',
    icon: 'fa-calendar',
    role: keycloakRoles.dataIgnorada,
    seletor: seletores.dataIgnorada
  },
  {
    path: '/dataignorada/:id',
    component: DataIgnoradaForm,
    title: 'Data Ignorada',
    icon: 'fa-calendar',
    parent: '/datasignoradas',
    role: keycloakRoles.dataIgnoradaUpdate,
    seletor: seletores.dataIgnorada,
    exact: true
  },
  // cadastros único
  {
    path: '/unico-agencia',
    component: UnicoCadastrosProtocolo,
    title: 'Agência',
    icon: 'fa-money-bill',
    role: keycloakRoles.unicoCadastros,
    seletor: seletores.unicoCadastros,
    exact: true
  },
  {
    path: '/unico-bairro',
    component: UnicoCadastrosProtocolo,
    title: 'Bairro',
    icon: 'fa-map-marker',
    role: keycloakRoles.unicoCadastros,
    seletor: seletores.unicoCadastros,
    exact: true
  },
  {
    path: '/unico-cidade',
    component: UnicoCadastrosProtocolo,
    title: 'Cidade',
    icon: 'fa-building',
    role: keycloakRoles.unicoCadastros,
    seletor: seletores.unicoCadastros,
    exact: true
  },
  {
    path: '/unico-legislacao',
    component: UnicoCadastrosProtocolo,
    title: 'Legislação',
    icon: 'fa-book-open',
    role: keycloakRoles.unicoCadastros,
    seletor: seletores.unicoCadastros,
    exact: true
  },
  {
    path: '/unico-logradouro',
    component: UnicoCadastrosProtocolo,
    title: 'Logradouro',
    icon: 'fa-map-signs',
    role: keycloakRoles.unicoCadastros,
    seletor: seletores.unicoCadastros,
    exact: true
  },
  {
    path: '/unico-pessoa',
    component: UnicoCadastrosProtocolo,
    title: 'Pessoa',
    icon: 'fa-users',
    role: keycloakRoles.unicoCadastros,
    seletor: seletores.unicoCadastros,
    exact: true
  },
  // assinatura
  {
    path: '/arquivos-assinatura',
    component: AssinaturaList,
    title: 'Assinaturas',
    icon: 'fa-file-signature',
    role: keycloakRoles.assinatura,
    seletor: seletores.assinatura,
    exact: true
  },
  // processos
  {
    path: '/processos-novo',
    component: ProcessosNovoList,
    title: LABEL_PROCESSOS,
    icon: 'fa-file',
    role: keycloakRoles.abertura,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/processos-novo/view/',
    component: ProcessoNovoView,
    title: 'Processo',
    icon: 'fa-file',
    parent: '/processos-novo',
    role: keycloakRoles.abertura,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/processo-novo/',
    component: ProcessoNovoForm,
    title: 'Processo',
    icon: 'fa-file',
    parent: '/processos-novo',
    role: keycloakRoles.aberturaUpdate,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/processos-novo/history',
    component: ProcessoNovoHistory,
    title: 'Consulta de Logs do Processo',
    icon: 'fa-file',
    parent: '/processos-novo',
    role: keycloakRoles.abertura,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/processos-novo/view/documentos',
    component: ProcessoDocumentosView,
    title: 'Visualização de documentos do Processo',
    icon: 'fa-file',
    parent: '/processos-novo/view/',
    role: keycloakRoles.abertura,
    seletor: seletores.abertura,
    exact: true,
    parentURLBuilder: ({ matchedURL }) =>
      matchedURL.replace(
        '/processos-novo/view/documentos',
        '/processos-novo/view'
      )
  },
  {
    path: '/tramitacao-dinamica',
    component: ProcessosAgrupados,
    title: 'Tramitação Dinâmica',
    icon: 'fa-layer-group',
    role: keycloakRoles.aberturaUpdate,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/comprovantes-entregas',
    component: ComprovanteEntregaProcessoList,
    title: 'Comprovantes de Entrega',
    icon: 'fa-clipboard-check',
    role: keycloakRoles.comprovante,
    seletor: seletores.comprovante,
    exact: true
  },
  {
    path: '/comprovante-entrega/:id',
    component: ComprovanteEntregaProcessoForm,
    title: 'Comprovante de Entrega',
    icon: 'fa-clipboard-check',
    role: keycloakRoles.comprovanteUpdate,
    seletor: seletores.comprovante,
    parent: '/comprovantes-entregas',
    exact: true
  },
  {
    path: '/processos-avaliacao',
    component: AvaliacaoProcessosList,
    title: 'Avaliar Processos Externos',
    icon: 'fa-check',
    role: keycloakRoles.abertura,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/correcaoFluxo',
    component: CorrecaoFluxo,
    title: 'Correção de Fluxo',
    icon: 'fa-undo',
    role: keycloakRoles.correcaoFluxo,
    seletor: seletores.correcaoFluxo,
    exact: true
  },
  {
    path: '/vincular-processos',
    component: VincularProcessos,
    title: `Vincular ${LABEL_PROCESSOS}`,
    icon: 'fa-random',
    role: keycloakRoles.anexar,
    seletor: seletores.anexar,
    exact: true
  },
  {
    path: '/controle-andamentos',
    component: ControleAndamentosList,
    title: 'Controle de Andamentos',
    icon: 'fa-paper-plane',
    role: keycloakRoles.controleAndamentosList,
    seletor: seletores.controleAndamentos,
    exact: true
  },
  // relatórios
  {
    path: '/processos/relatorios/comprovante-encaminhamento',
    component: RelatorioComprovanteEncaminhamento,
    title: 'Relatório de Comprovante de Encaminhamento',
    icon: 'fa-receipt',
    role: keycloakRoles.relatorioComprovanteEncaminhamento,
    seletor: seletores.relatorios,
    exact: true
  },
  {
    path: '/processos/relatorios/estatisticas-processos',
    component: EstatisticasProcesso,
    title: 'Relatório de Estatísticas Processos',
    icon: 'fa-list-ul',
    role: keycloakRoles.relatorioEstatisticasProcessos,
    seletor: seletores.relatorios,
    exact: true
  },
  {
    path: '/processos/relatorios/etiqueta',
    component: RelatorioEtiqueta,
    title: 'Relatório de Etiquetas do Processo',
    icon: 'fa-paper-plane',
    role: keycloakRoles.relatorioEtiqueta,
    seletor: seletores.relatorios,
    exact: true
  },
  {
    path: '/processos/relatorios/geral',
    component: RelatorioPocessoGeral,
    title: 'Relatório de Processos',
    icon: 'fa-copy',
    role: keycloakRoles.relatorioGeral,
    seletor: seletores.relatorios,
    exact: true
  },
  {
    path: '/processos/relatorios/tramite',
    component: RelatorioTramite,
    title: 'Relatório de Trâmites',
    icon: 'fa-paper-plane',
    role: keycloakRoles.relatorioTramite,
    seletor: seletores.relatorios,
    exact: true
  },
  {
    path: '/processos/relatorios/tramite-por-local',
    component: RelatorioTramitePorLocal,
    title: 'Relatório de Trâmites por Local',
    icon: 'fa-paper-plane',
    role: keycloakRoles.relatorioTramite,
    seletor: seletores.relatorios,
    exact: true
  },
  {
    path: '/processos/relatorios/processo-receber-encaminhar',
    component: RelatorioReceberEncaminhar,
    title: 'Relatório de Processos a Encaminhar/Receber',
    icon: 'fa-clipboard-check',
    role: keycloakRoles.relatorioTramite,
    seletor: seletores.relatorios,
    exact: true
  },
  // consultas e relatórios dinâmicos
  {
    path: '/unico-consultas-dinamicas',
    component: UnicoConsultaDinamica,
    title: 'Consultas Dinâmicas',
    icon: 'fa-magic',
    role: keycloakRoles.unicoConsultaDinamicaRead,
    seletor: seletores.unicoConsultaDinamica,
    exact: true
  },
  {
    path: '/unico-relatorios-dinamicos',
    component: UnicoRelatorioDinamico,
    title: 'Relatórios Dinâmicos',
    icon: 'fa-file-alt',
    role: keycloakRoles.unicoRelatorioDinamico,
    seletor: seletores.unicoConsultaDinamica,
    exact: true
  },
  // modelos de documentos
  {
    path: '/modelos-documentos',
    component: ModelosDocumentosList,
    title: 'Modelos',
    icon: 'fa-table',
    role: keycloakRoles.modelosDocumentos,
    seletor: seletores.modelosDocumentos,
    exact: true
  },
  {
    path: '/modelos-documentos/:id',
    component: ModelosDocumentosForm,
    title: 'Modelo',
    icon: 'fa-table',
    parent: '/modelos-documentos',
    role: keycloakRoles.modelosDocumentosUpdate,
    seletor: seletores.modelosDocumentos,
    exact: true
  },
  {
    path: '/variaveis',
    component: VariaveisList,
    title: 'Variáveis/SQL',
    icon: 'fa-list',
    role: keycloakRoles.variaveis,
    seletor: seletores.variaveis,
    exact: true
  },
  {
    path: '/variaveis/:id',
    component: VariaveisForm,
    title: 'Variáveis/SQL',
    icon: 'fa-list',
    parent: '/variaveis',
    role: keycloakRoles.variaveisUpdate,
    seletor: seletores.variaveis,
    exact: true
  },
  // configurações
  {
    path: '/enviar-processos',
    component: EnviarProcessosTributos,
    title: 'Integração Processos Tributos',
    icon: 'fa-file',
    role: keycloakRoles.integracaoTributos,
    seletor: seletores.integracaoTributos,
    exact: true
  },
  {
    path: '/parametros',
    component: ParametroList,
    title: 'Parâmetros',
    icon: 'fa-tasks',
    role: keycloakRoles.parametros,
    seletor: seletores.parametros,
    exact: true
  },
  {
    path: '/parametrizacoes-integracao',
    component: ParametrizacaoIntegracaoList,
    title: 'Parametrizações de Integração',
    icon: 'fa-random',
    role: keycloakRoles.parametrosIntegracaoRead,
    seletor: seletores.parametros,
    exact: true
  },
  {
    path: '/parametrizacoes-integracao/:id',
    component: ParametrizacaoIntegracaoForm,
    title: 'Parâmetro de Integração',
    icon: 'fa-random',
    parent: '/parametrizacoes-integracao',
    role: keycloakRoles.parametrosIntegracaoUpdate,
    seletor: seletores.parametros,
    exact: true
  },
  // configurações assinaturas
  {
    path: '/assinantes',
    component: AssinanteList,
    title: 'Assinantes',
    icon: 'fa-users',
    role: keycloakRoles.assinante,
    seletor: seletores.assinante
  },
  {
    path: '/assinantes/:id',
    component: AssinanteForm,
    title: 'Assinante',
    icon: 'fa-users',
    parent: '/assinantes',
    role: keycloakRoles.assinanteUpdate,
    seletor: seletores.assinante,
    exact: true
  },
  {
    path: '/assinatura-configuracao',
    component: AssinaturaConfigForm,
    title: 'Configuração Assinatura',
    icon: 'fa-tasks',
    role: keycloakRoles.assinaturaConfig,
    seletor: seletores.assinaturaConfig,
    exact: true
  },
  {
    path: '/fluxos-assinaturas',
    component: FluxoAssinaturaList,
    title: 'Fluxos de Assinaturas',
    icon: 'fa-link',
    role: keycloakRoles.fluxoAssinatura,
    seletor: seletores.fluxoAssinatura
  },
  {
    path: '/fluxos-assinaturas/:id',
    component: FluxoAssinaturaForm,
    title: 'Fluxo de Assinatura',
    icon: 'fa-link',
    parent: '/fluxos-assinaturas',
    role: keycloakRoles.fluxoAssinaturaUpdate,
    seletor: seletores.fluxoAssinatura,
    exact: true
  },
  {
    path: '/grupos-assinantes',
    component: GrupoAssinanteList,
    title: 'Grupos de Assinantes',
    icon: 'fa-object-group',
    role: keycloakRoles.grupoAssinante,
    seletor: seletores.grupoAssinante
  },
  {
    path: '/grupos-assinantes/:id',
    component: GrupoAssinanteForm,
    title: 'Grupo de Assinante',
    icon: 'fa-object-group',
    parent: '/grupos-assinantes',
    role: keycloakRoles.grupoAssinanteUpdate,
    seletor: seletores.grupoAssinante,
    exact: true
  },
  {
    path: '/processo-mp/view',
    component: ProcessoMpViewPage,
    title: LABEL_PROCESSO,
    icon: 'fa-file',
    parent: '/processos-novo',
    role: keycloakRoles.abertura,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/processo-mp',
    component: ProcessoMPPage,
    title: LABEL_PROCESSO,
    icon: 'fa-file',
    parent: '/processos-novo',
    role: keycloakRoles.aberturaUpdate,
    seletor: seletores.abertura,
    exact: true
  },
  {
    path: '/tipos-andamento',
    component: TipoAndamentoList,
    title: 'Tipos de andamento',
    icon: 'fa-align-left',
    role: keycloakRoles.tipoAndamento,
    seletor: seletores.tipoAndamento
  },
  {
    path: '/tipo-andamento/:id',
    component: TipoAndamentoForm,
    title: 'Tipo de andamento',
    icon: 'fa-align-left',
    parent: '/tipos-andamento',
    role: keycloakRoles.tipoAndamentoUpdate,
    seletor: seletores.tipoAndamento,
    exact: true
  },
  {
    path: '/processos/relatorios/controle-emails',
    component: ControleEmailList,
    title: 'Controle de Emails',
    icon: 'fa-align-left',
    role: keycloakRoles.relatorioControleEmails,
    seletor: seletores.relatorios
  }
];

export const Routes = () => {
  return (
    <Switch>
      {publicRoutes.map(route => (
        <Route key={route.path} {...route} exact />
      ))}
      {protectedRoutes.map(route => (
        <ProtocoloProtectedRoute key={route.path} {...route} exact />
      ))}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export const allRoutes = [...publicRoutes, ...protectedRoutes];
