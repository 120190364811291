import {
  ActionButton,
  ActionsGroup,
  Container,
  FAB,
  Field,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import { TableContainer } from 'common/components';
import { ModeloDadosService } from 'common/service';
import { ModeloDados, Page, Pagination, Sort } from 'common/type';
import { History } from 'history';
import { Component } from 'react';

import {
  CategoriaModeloEnum,
  CategoriaModeloEnumFilterOptions,
  CategoriaModeloEnumFormatado
} from '../../type/enum';

type Props = {
  history: Pick<History, 'push'>;
  service: ModeloDadosService;
  categorias: CategoriaModeloEnum[];
};

type State = {
  search: string;
  sort: Sort;
  page?: Page;
  pagination?: Pagination;
  isLoading: boolean;
  modelos: ModeloDados[];
};

const searchFields: Field[] = [
  {
    label: 'Código',
    name: 'codigo',
    type: 'NUMBER'
  },
  {
    label: 'Nome',
    name: 'nome',
    type: 'STRING'
  },
  {
    label: 'Categoria',
    name: 'categoria',
    type: 'ENUM',
    options: Object.entries(CategoriaModeloEnumFilterOptions).map(
      ([name, descricao]) => ({
        name,
        descricao
      })
    )
  }
];

class PrestacaoContaListComponent extends Component<Props, State> {
  state: State = {
    modelos: [],
    search: '',
    page: undefined,
    sort: {
      sort: 'nome,desc'
    },
    pagination: undefined,
    isLoading: true
  };

  onSearch = (
    search = this.state.search,
    page?: any,
    sort: Sort = this.state.sort
  ) => {
    let customSearch =
      search !== '' ? `codigo!=null and ${search}` : 'codigo!=null';
    if (this.props.categorias && this.props.categorias.length) {
      const categorias = this.props.categorias.join(',');
      customSearch += ` and categoria=in=(${categorias})`;
    }
    this.setState({ search: customSearch, page, sort, isLoading: true }, () => {
      this.props.service
        .findAll(customSearch, page, sort)
        .then(response => response.data)
        .then(({ content, ...pagination }) => {
          this.setState(prevState => ({
            ...prevState,
            modelos: content,
            pagination
          }));
        })
        .finally(() => this.setState({ isLoading: false }));
    });
  };

  paginationSearch = (page: Page) => {
    this.setState({ page }, () => {
      this.onSearch(undefined, page, this.state.sort);
    });
  };

  onEdit(id: string): void {
    this.props.history.push(`/modelo-dados/${id}`, {
      simam: true
    });
  }

  sortOnHeaderColumnClick = (sort: Sort) => {
    this.onSearch(this.state.search, this.state.page, sort);
  };

  render() {
    const { modelos, pagination, isLoading } = this.state;

    return (
      <Container breadcrumb>
        <TableContainer>
          <SearchFilter fields={searchFields} search={this.onSearch} />
          <Table
            values={modelos}
            loading={isLoading}
            keyExtractor={(modelo: ModeloDados) => modelo.id!}
            sortOnHeaderColumnClick={this.sortOnHeaderColumnClick}
            defaultSort={{ name: 'modelo', direction: 'desc' }}
          >
            <Table.Column
              header="Código"
              value={(modelo: ModeloDados) => modelo.codigo}
              sortable
              name="codigo"
            />
            <Table.Column
              header="Nome"
              value={(modelo: ModeloDados) => modelo.nome}
              sortable
              name="nome"
            />
            <Table.Column
              header="Categoria"
              value={(modelo: ModeloDados) =>
                modelo.categoria
                  ? `${CategoriaModeloEnumFormatado[modelo.categoria!]}`
                  : 'N/D'
              }
            />
            <Table.Column
              header=""
              value={(modelo: ModeloDados) => (
                <ActionsGroup>
                  <ActionButton
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => this.onEdit(modelo.id!)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && pagination.totalPages > 0 && (
            <SearchPagination
              page={pagination}
              searchWithPage={this.paginationSearch}
            />
          )}
        </TableContainer>
        <div className="btn-save">
          <FAB
            icon="plus"
            iconColor="white"
            title="Novo modelo"
            onClick={() =>
              this.props.history.push('/modelo-dados/new', {
                simam: true
              })
            }
          />
        </div>
      </Container>
    );
  }
}

export { PrestacaoContaListComponent };
