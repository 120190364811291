/* Assinatura */
export const assinatura = 'Frm_Assinatura';
export const assinaturaConfig = 'Frm_Assinatura_Config';

/* Cadastros */
export const assinante = 'Frm_Assinante';
export const assunto = 'Frm_Assunto';
export const bairro = 'Frm_Bairro';
export const caixa = 'Frm_Caixa';
export const cidade = 'Frm_Cidade';
export const coordenadoria = 'Frm_Coordenadoria';
export const diretoria = 'Frm_Diretoria';
export const documentos = 'Frm_documentos';
export const entidade = 'Frm_Entidade';
export const fluxoAssinatura = 'Frm_FluxoAssinatura';
export const gerencia = 'Frm_Gerencia';
export const grauSigilo = 'Frm_GrauSigilo';
export const grupoAssinante = 'Frm_GrupoAssinante';
export const local = 'Frm_Local';
export const logradouro = 'Frm_Logradouro';
export const pessoa = 'Frm_Pessoa';
export const roteiro = 'Frm_Roteiro';
export const secretaria = 'Frm_Secretaria';
export const situacao = 'Frm_Situacao';
export const tipo = 'Frm_Tipo';
export const tipoNotificacao = 'Frm_TipoNotificacao';
export const tipoParticipante = 'Frm_TipoParticipante';
export const unidade = 'Frm_Unidade';
export const unidadeOrcamentaria = 'Frm_UnidOrcamentaria';
export const usuario = 'Frm_Usuario';
export const atualizacaoPessoa = 'Frm_AtualizacaoPessoa';
export const modeloImpressao = 'Frm_ModeloImpressao';
export const dataIgnorada = 'Frm_DataIgnorada';

/* Processos */
export const processos = 'Frm_Abertura';
export const aberturaCreate = 'Frm_Abertura';
export const processosAvaliar = 'Frm_AvaliacaoProcesso';
export const abertura = 'Frm_Abertura';
export const anexar = 'Frm_Anexar';
export const referenciar = 'Frm_Referenciar';
export const comprovante = 'Frm_Comprovante';
export const integracaoTributos = 'Frm_IntegracaoTributos';
export const agrupamentoArquivos = 'Frm_AgrupamentoArquivos';

/* Cubejs Dashboards */
export const cubejsDashboards = 'Frm_CubeJsDashboards';

/* Tramites */
export const receberProcesso = 'Frm_Receber';
export const encaminharProcesso = 'Frm_Encaminhar';
export const arquivarProcesso = 'Frm_Arquivar';
export const reabrirProcesso = 'Frm_Reabrir';
export const correcaoFluxo = 'Frm_CorrecaoFluxo';

/* Relatórios e configurações */
export const parametros = 'Frm_Parametros';
export const relatorios = 'Frm_RelCarregarRel';
export const unicoRelatorioDinamico = 'Frm_UnicoRelatorioDinamico';

/* Consultas */
export const unicoConsultaDinamica = 'Frm_UnicoConsultaDinamica';

/* Único */
export const unicoCadastros = 'Frm_UnicoCadastros';

/* Modelos de Documentos */
export const modelosDocumentos = 'Frm_ModelosDocumentos';
export const variaveis = 'Frm_Variaveis';

/* Indicadores */
export const indicadorProcessosLocais = 'Frm_I_Processos_Locais';

/* Tipo andamento */
export const tipoAndamento = 'Frm_TipoAndamento';

export const controleAndamentos = 'Frm_ControleAndamentos';

export const reordenarAnexos = 'Frm_Reordenar_Anexos';
