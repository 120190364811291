import React, { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  classes?: string;
  title?: string;
  withIcon?: boolean;
  renderButtons?: () => React.ReactNode;
  type?: 'INFO' | 'SUCCESS' | 'ERROR' | 'WARNING';
};

export const Informativo: FC<Props> = ({
  children,
  classes = '',
  title = '',
  withIcon = false,
  renderButtons,
  type
}) => {
  let colorClass = '';
  let iconClass = '';

  switch (type) {
    case 'INFO':
      iconClass = 'fa-info-circle';
      colorClass = 'primary';
      break;

    case 'SUCCESS':
      iconClass = 'fa-check-circle';
      colorClass = 'positive';
      break;

    case 'ERROR':
      iconClass = 'fa-times-circle';
      colorClass = 'negative';
      break;

    case 'WARNING':
      iconClass = 'fa-exclamation-triangle';
      colorClass = 'warning';
      break;

    default:
      break;
  }

  if (withIcon || renderButtons) {
    return (
      <div
        className={`info ${colorClass} ${withIcon ? 'icon' : ''} ${classes}`}
      >
        {withIcon && (
          <div className="icon">
            <em className={`fa ${iconClass}`} />
          </div>
        )}

        <article>
          <h4>{title}</h4>
          <p>{children}</p>
        </article>

        {renderButtons?.()}
      </div>
    );
  }

  return (
    <article className={`info ${colorClass} ${classes}`}>{children}</article>
  );
};
